.footer {
    width: 100%;
    height: max-content;
    background-color:#001d3a;
    z-index: 1;
    font-family: 'Poppins', sans-serif;
}

.footerTitle{
    color: white;
    text-align: center;
    font-size: 1.5vw;
    padding-top: 1vw;
}

.footerSocials{
    text-align: right;
    padding: '1vw',
}

.gradient-line-hor {
    margin: auto;
    margin-top: 0.8vw;
    height: .4vw;
    width: 100%;
    background: linear-gradient(to right,#fd662f 0,#fd662f 9%,#72a8be 9%,#72a8be 22%,#0070c0 22%,#0070c0 50%,#9ee0f9 50%,#9ee0f9 100%);
}

.footerItems{
    width: 80%;
    margin: auto;
}

.footerTopInfo{
    color: white;
    font-size: '1vw';
    padding: '1vw';
    margin-top: -1vw;
}

.footerColumns {
    display: flex;
    justify-content: space-around; /* Adjust this as needed */
 }
 
 .footerColumn {
    flex: 1;
    margin: 0 1vw; /* Adjust the margin between columns */
    text-align: center;
    justify-content: center;
 }
 