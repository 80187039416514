.hubintroTS {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    margin-top: -0.3%;
    margin-bottom: -3.4%;
    margin-right: 3.5%;
    margin-left: 3.5%;
}

#rightsunTS {
    height: 6%;
    width: 6%;
    margin-right: 12%;
    margin-top: -0.6%;
}

#rightsunTS:hover {
    animation: 1.2s 0s 1 rotl;
}

#leftsunTS {
    height: 6%;
    width: 6%;
    margin-left: 12%;
    margin-top: -0.6%;
}

#leftsunTS:hover {
    animation: 1.2s 0s 1 rotr;
}

.carousel {
    z-index: 0;
}

.FormGroupTS {
    margin: auto;
    font-family: 'Poppins', sans-serif;
    width: 50%;
    margin-top: 4vw;
    margin-bottom: 1.5vw;
    padding: 0;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    border-style: none;
    background-color: #ffffff;
    color: cornsilk;
    box-shadow: 0 1px 2px rgb(215, 215, 218), 0 2px 4px rgb(223, 223, 223),
        inset 0 1px 0 #f2f2f2;
    border-radius: 4px;
}

.FormRowTS {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.FormRowTV {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 0px;
}

.FormRowTS:first-child {
    border-top: none;
}

.FormRowLabelDropDownTS {
    width: 40%;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 3%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    color: rgb(150, 150, 150);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.FormRowLabelDropDownTestUpload {
    width: 100%;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 3%;
    margin-right: 4%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    color: rgb(150, 150, 150);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.FormRowLabelDropDownTestView {
    width: 100%;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 3%;
    margin-right: -2%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    color: rgb(150, 150, 150);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.classitem {
    margin-left: 5%;
    margin-top: 4%;
    padding: 1.25vw;
    padding-top: 2vmin;
    padding-bottom: 2vmin;
    font-size: 18;
    box-sizing: border-box;
    width: 100%;
    color: black;
    border: 0.1vmin solid rgb(213, 201, 218);
    border-start-end-radius: 1vw;
    border-radius: 1vw;
    overflow: auto;
    float: right;
    position: relative;
}

.FormRowInputTS {
    text-align: left;
    font-size: 1.2vw;
    width: 0%;
    color: #001d3a;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    animation: 1ms void-animation-out;
}

.FormRowInputViewTests {
    font-size: 1.2vw;
    width: 80%;
    padding-top: 0.0%;
    color: #001d3a;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    animation: 1ms void-animation-out;
    margin-left: -5vw;
}

.hrTS {
    color: white;
    border: 0.01vw solid rgba(218, 218, 218, 0.338);
    width: 85%;
}

.disclaimer3 {
    font-size: 0.9vw;
    font-family: 'Poppins', sans-serif;
    color: rgb(169, 169, 169);
    margin-left: 11.5%;
    margin-right: 5.5%;
    margin-top: 0%;
    margin-bottom: 2%;
    font-style: italic;
}

.disclaimer4 {
    font-size: 0.9vw;
    font-family: 'Poppins', sans-serif;
    color: #001d3a;
    margin-left: 40%;
    margin-right: 5.5%;
    margin-top: 0%;
    margin-bottom: 2%;
    margin: auto;
    width: 30%;
    padding-left: 40%;
    text-align: center;
    font-style: italic;
}

.checkboxTS {
    margin-left: 5.2%;
    margin-right: 3%;
    background: #001d3a;
}

.checkboxTSinvis {
    margin-left: 5.2%;
    margin-right: 3%;
    background: #001d3a;
    height: 1%;
}

.progressbarTS {
    width: 60%;
    margin-top: 1vw;
    margin: auto;
    padding: 0;
    text-align: center;
    border: 0.05vw solid rgba(176, 176, 176, 0.701);
    border-radius: 0.1vw;
}

.uploadSuccess {
    text-align: center;
    font-size: 0.9vw;
    font-family: 'Poppins';
    color: #001d3a;
}

.nextprevdiv {
    margin-top: 1vw;
    margin: auto;
    padding: 0;
    width: 50%;
    text-align: center;
}

.nextprevdivlifted {
    margin: auto;
    margin-top: -24vw;
    padding: 0;
    width: 50%;
    text-align: center;
}

.nextprevdivlifted2 {
    margin: auto;
    margin-top: -12vw;
    padding: 0;
    width: 50%;
    text-align: center;
}

.buttonSlider {
    font-family: 'Poppins', sans-serif;
    font-size: 1.3vw;
    width: 20%;
    margin-left: 4%;
    margin-right: 4%;
    height: 2.8vw;
    color: white;
    background-color: #001d3a;
    /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); */
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.buttonADD {
    font-family: 'Poppins', sans-serif;
    font-size: 1vw;
    width: 12%;
    margin-bottom: 1%;
    text-align: center;
    margin-left: 44%;
    height: 2.2vw;
    color: white;
    background-color: #001d3a;
    /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); */
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.buttonADD:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}

.buttonADD:disabled {
    background-color: rgb(230, 230, 230);
    color: rgb(155, 155, 155);
    pointer-events: none;
}

.buttonADD:disabled:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(155, 155, 155);
    pointer-events: none;
}

.buttonSlider:disabled {
    background-color: rgb(230, 230, 230);
    color: rgb(155, 155, 155);
    pointer-events: none;
}
.buttonSlider:disabled:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(155, 155, 155);
    pointer-events: none;
}

.buttonSlider:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}