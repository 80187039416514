@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.navTop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 102px;
    padding: 15px 60px;
    box-sizing: border-box;
    transition: 0.8s;
    -webkit-transition: 0.8s;
    overflow: hidden;
    z-index: 4;  /*was 4*/
}

.navElse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 115px;
    padding: 15px 60px;
    box-sizing: border-box;
    transition: 0.6s;
    -webkit-transition: 0.6s;
    background-color: white;
    box-shadow: 0vw 0.01vw 1.3vw -0.75vw rgba(0, 0, 0, 0.865);
    overflow: hidden;
    z-index: 4;
}

.navTop .logo {
    position: absolute;
    float: left;
}
.navElse .logo {
    position: absolute;
    float: left;
}

.navTop .logo img {
    height: 80px;
    padding-top: 3px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    margin-left: -40px;
}
.navElse .logo img {
    height: 80px;
    padding-top: 3px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    margin-left: -40px;
}

.navTop .ulNav {
    float: right;
    margin: 0;
    margin-top: 16px;
    padding: 0;
    display: flex;
    margin-right: -30px;
}
.navElse .ulNav {
    float: right;
    margin: 0;
    margin-top: 16px;
    padding: 0;
    display: flex;
    margin-right: -30px;
}

.navTop .ulNav .liNav {
    list-style: none;
    line-height: 36px;
    padding: 10px 0.3vw;
    margin-left: 16px;
    text-decoration: none;
    transition: 0.5s;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    border-radius: 50px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}
.navElse .ulNav .liNav {
    list-style: none;
    line-height: 36px;
    padding: 10px 0.3vw;
    margin-left: 16px;
    text-decoration: none;
    transition: 0.5s;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    border-radius: 50px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.navTop .ulNav .liNav:hover {
    background: rgba(254, 254, 84, 0.892);
    border-radius: 100px;
    font-size: 18px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}
.navElse .ulNav .liNav:hover {
    background: rgba(254, 254, 84, 0.892);
    border-radius: 100px;
    font-size: 18px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.signin {
    list-style: none;
    line-height: 36px;
    padding: 10px 0.3vw;
    margin-left: 16px;
    text-decoration: none;
    transition: 0.5s;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    border-radius: 50px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    margin-left: 22px;
    background:  rgba(254, 254, 84, 0.892);
}

.checkbtn {
    font-size: 30px;
    color: white;
    float: right;
    line-height: 80px;
    height: 40px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
    color: rgb(255, 255, 128);
}

.iEq {
    color: black;
    font-style: normal;
    font-size: 60px;
}

#check {
    display: none;
}

/* For mobile and small devices */
@media (max-width: 1500px) {

    .navTop .logo img {
        height: 30px;
        padding-top: 30px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        margin-left: -40px;
    }
    .navElse .logo img {
        height: 30px;
        padding-top: 30px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        margin-left: -40px;
    }
    .checkbtn {
        display: block;
    }
    .navTop {
        color: white;
        z-index: 3; /*1*/
    }
    .navElse {
        color: white;
        z-index: 3; /*1*/
    }
    .navTop .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: -100%;
        align-items: center;
        display: block;
        text-align: center;
        
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
    }
    .navElse .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: -100%;
        align-items: center;
        display: block;
        text-align: center;
        /*
        transition: all 0.5s;
        -webkit-transition: all 0.5s;*/
    }
    .navTop .ulNav .liNav {
        font-size: 1.8rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        margin-left: 0;
    }
    .navElse .ulNav .liNav {
        font-size: 1.8rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        margin-left: 0;
        position: relative;
    }
    .navTop .ulNav .liNav:hover {
        font-size: 1.9rem;
    }
    .navElse .ulNav .liNav:hover {
        font-size: 1.9rem;
    }
    .navElse {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 115px;
        padding: 15px 60px;
        box-sizing: border-box;
        background-color: white;
    }
    .navTop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 115px;
        padding: 15px 60px;
        box-sizing: border-box;
        background-color: white;
    }
    .signin {
        list-style: none;
        line-height: 36px;
        padding: 10px 0.3vw;
        margin-left: 16px;
        text-decoration: none;
        transition: 0.5s;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        border-radius: 50px;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        font-size: 1.8rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        margin-left: 0;
        margin-left: 0em;
        color: black;
    }
    .centered {
        color: red;
    }
    #check:checked ~ .ulNav {
        left: 0;
        -webkit-text-stroke-width: 0.0px;
    }


}


@media (max-width: 600px) {
    .navTop .logo img {
        height: 40px;
        padding-top: 27px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
    }
    .navElse .logo img {
        height: 40px;
        padding-top: 27px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
    }
    .iEq {
        color: black;
        font-style: normal;
        font-size: 40px;
        margin-right: -50px;
        margin-left: 50px;
    }
    
    
    #check:checked ~ .ulNav {
        left: 0;
        -webkit-text-stroke-width: 0.0px;
    }
}


@media (max-width: 300px) {
    .navTop .logo img {
        height: 25px;
        padding-top: 25px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        margin-left: -40px;
    }
    .navElse .logo img {
        height: 25px;
        padding-top: 25px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        margin-left: -40px;
    }
    
    .iEq {
        color: black;
        font-style: normal;
        font-size: 60px;
        margin-right: -30px;
    }

    .navTop .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: -100%;
        align-items: center;
        display: block;
        text-align: center;
        /*
        transition: all 0.5s;
        -webkit-transition: all 0.5s;*/
    }
    .navElse .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: -100%;
        align-items: center;
        display: block;
        text-align: center;
        /*
        transition: all 0.5s;
        -webkit-transition: all 0.5s;*/
    }
}

/*
@media only screen and (max-width: 600px) {
    .iEq {
        color: black;
        font-style: normal;
        font-size: 60px;
    }
    .navTop .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: -100%;
        align-items: center;
        display: block;
        text-align: center;
    }
    .navElse .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: 0%;
        align-items: center;
        display: block;
        text-align: center;
    }
    .navTop .ulNav {
        left: 0%;
    }
}
*/