@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


.container {
    text-align: center;
    background-image: url("royce2.jpeg");

    /* Set a specific height */
    width: 100%;
    height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;

    z-index: -3;
}

.parallax {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    object-fit: cover;
}

.centered {
    position: absolute;
    margin-top: 19.5%;
    margin-top: calc(40vh);
    /*font-family: Georgia,Times,Times New Roman,serif; */
    font-family: Poppins;
    font-family: Georgia,Times,Times New Roman,serif;
    font-family: Poppins;
    font-size: 4.5vw;
    width: 100%;
    color: white;
    transition: 0.3s;
    letter-spacing: 0.04rem;
    z-index: 0;

    pointer-events: none;

    -webkit-text-stroke-width: 0.001vw;
    -webkit-text-stroke-color: white;

}

.scrolled {
    position: absolute;
    margin-top: 57vh;
    font-family: Montserrat;
    font-size: 4.4vw;
    font-family: Georgia,Times,Times New Roman,serif;
    font-family: 'Poppins', sans-serif;
    font-size: 4.2vw;
    width: 100%;
    color: rgba(255, 255, 255, 0);
    transition: 0.5s;
    pointer-events: none;

    perspective: 100px;

    -webkit-text-stroke-width: 0.0px;
    -webkit-text-stroke-color: white;
}

.HRcentered {
    border: 0.33vw solid #001d3a;
    width: 52%;
    transition: 0.15s;
    margin-top: 1%;
    z-index: 0;
}

.HRscrolled {
    border: 0 solid #001d3a;
    width: 45%;
    transition: 0.15s;
}

.bottom {
    margin-top: -0.5%;
    font-size: 3.3vw;
    transition: ease 0.0s;

    -webkit-text-stroke-width: 0.0vw;
    -webkit-text-stroke-color: white;
}

.bottomscrolled {
    font-size: 2.8vw;
    transition: ease 0.2s;
    margin-top: 3%;
    color: rgba(255, 255, 255, 0);
    letter-spacing: 0.1rem;

}

.container3 {
    text-align: center;

    /* Set a specific height */
    width: 100%;
    height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;

    z-index: -3;
}


@media (max-width: 650px) {
    .centered {
        margin-top: 46vh;
    }
}
