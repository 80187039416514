.navgapA {
    margin-top: 115px;
}

.haccount {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 3.1vw;
    color: #001d3a;
    margin-top: 30px;
}
.haccountMerch {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 1vw;
    color: #001d3a;
    margin-top: 0px;
}

.haccountMerchItem {
    font-family: 'Poppins', sans-serif;
    font-size: 2.9em;
    color: #001d3a;
}

.graa {
    background-color: rgb(249, 247, 247);
}

.adminPageLink {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  text-decoration-line: none;
  font-size: 16px;
  height: 26px;
  color: white;
  margin-right: 40%;
  border-radius: 4px;
  margin-left: 40%;
  background-color: #001d3a;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  margin-top: 40px;
}

.signInButton a{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;   
    background-color: #001d3a;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 4px;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    height: 50px;
    margin-left: 40%;
    margin-right: 40%;
}

.signInButton:hover(){
    cursor: pointer;
}