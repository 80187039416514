@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.container2 {
    text-align: left;

    width: 100%;
    height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;
    max-width: 100%;
}

.parallax2 {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    object-fit: cover;
}

.centered2 {
    position: absolute;
    margin-top: 34%;
    font-family: Poppins;
    font-size: 6.5vw;
    letter-spacing: 0.05rem;
    width: 100%;
    color: white;
    transition: 0.3s;
    pointer-events: none;
    -webkit-text-stroke-width: 0.25vw;
    -webkit-text-stroke-color: white;
}

.scrolled2 {
    position: absolute;
    margin-top: 34%;
    /* font-family: Georgia,Times,Times New Roman,serif; */
    font-family: Poppins;
    font-size: 6.2vw;
    width: 100%;
    color: rgba(255, 255, 255, 0);
    transition: 0.3s;
    pointer-events: none;
    -webkit-text-stroke-width: 0.0px;
}


@media screen and (max-width: 800px) {
    .centered2 {
        margin-top: 130%;
        font-size: 9vw;
    }
}