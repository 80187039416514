.accountNav {
    background-color: #001d3a;
    height: 45px;
    left: 0;
    margin: 0 auto;
    width: 103.5vw;
    text-align: center;
}

.ulist {
    margin: 0;
    margin-top: 9px;
    padding: 0;
    display: inline-block;
    list-style: none;
}

.mininavli {
    display: inline;
    transition: 0.3s;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 12.2px;
    padding-bottom: 10px;
}

.mininavli:hover {
    transition: ease 0.4s;
    background: rgb(136, 136, 136);
    padding-top: 12.2px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
}


@media (max-width: 750px) {
    .ulist {
        margin: 0;
        margin-top: 10px;
        padding: 0;
        display: inline-block;
        list-style: none;
    }
    .mininavli {
        font-size: 16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 11px;
    }
    .mininavli:hover {
        transition: ease 0.4s;
        background: rgb(136, 136, 136);
        padding-top: 14px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 11px;
    }
}