@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


html, body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  height:100%
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /*used for event dates/times*/
  margin-right: 3px;
}


.fc .fc-button-primary {
  color: black;
  color: var(--fc-button-text-color, black);
  background-color: rgb(254 254 84 / 89%);
  background-color: var(--fc-button-bg-color, rgb(254 254 84 / 89%));
  border-color: white;
  border-color: var(--fc-button-border-color, white);
  border-width: medium;
  border-radius: 15px;
  font-family: 'Poppins',sans-serif;
}

.fc .fc-toolbar-title {
  font-size: 1.75em;
  margin: 0;
  color: rgb(0, 29, 58);
  font-family: 'Poppins', sans-serif
}

.fc-h-event .fc-event-title {
  display: inline-block;
  vertical-align: top; 
  left: 0;  
  right: 0; 
  max-width: 100%; 
  overflow: hidden;
  font-family: 'Poppins',sans-serif;
}

.demo-app {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  min-height: 100%;
  font-family: 'Poppins',Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0 7.5% 0 7.5%;
}
.top{
  flex: 0.3;
  line-height: 1.3;
  margin-top: 20px;
  /*
  margin-left: 18%;
  width: 32%;*/
  justify-content: space-between;
  /*align-items: flex-start;*/
}

.cal-event-bar{
  border-color: rgb(0, 29, 58);
  border-style: solid;
  border-radius: 15px;
  /*border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;*/
  border-width: medium;
  color: white;
  background-color: rgb(0, 29, 58);
  margin-top: 80px;
}

.bottom-calendar-main {
  padding: 3em;
  align-items: center;
  justify-content: center;
  z-index: 2;
  flex: 1;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

.titleEventClicked{
  font-size: 1.2vw;
}

.otherInfo{
  font-size: 0.9vw;
}


@media screen and (max-width: 1200px)
{
  .demo-app{
    flex-direction: column;
  }

  .top{
    margin-top: -50px;
    margin-left: 8%;    
    width: max-content;
    /*padding: 0px 20px 0 40px;*/
  }

  .titleEventClicked{
    padding-right: 20px;
  }

  .otherInfo{
    padding-right: 20px;
  }

  .bottom-calendar-main{
    margin-left: 4%;
    margin-right: 4%;
  }

  .titleEventClicked{
    font-size: 1.8vw;
  }

  .otherInfo{
    font-size: 1.4vw;
  }
}

@media screen and (max-width: 800px) {
  .top{
    padding-top: 0px;
    margin-left: 4%;
    line-height: 1;
  }

  .bottom-calendar-main{
    margin-left: -2%;
    margin-right: -2%;
  }

  .titleEventClicked{
    font-size: 2.6vw;
  }

  .otherInfo{
    font-size: 2.4vw;
  }
}