.navgap {
    margin-top: 125px;
}

.sponsorCards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 2%;
    margin-right: 2%;
}

.card{
    margin-left: 2%;
    margin-right: 2%;
}

.card img{
    width: 20vw;
}