.FormGroupPC {
    margin: auto;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    width: 50%;
    margin-top: 4vw;
    margin-bottom: 1.5vw;
    padding: 0;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    border-style: none;
    background-color: #ffffff;
    color: cornsilk;
    box-shadow: 0 1px 2px rgb(215, 215, 218), 0 2px 4px rgb(223, 223, 223),
        inset 0 1px 0 #f2f2f2;
    border-radius: 4px;
}

.FormRowPC {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 1%;
    padding-bottom: 1%;
}

.FormRowInput::placeholder {
    color: rgb(155, 155, 155);
  }

.buttonRESET {
    font-family: 'Poppins', sans-serif;
    font-size: 1vw;
    min-width: 20%;
    margin-bottom: 1%;
    text-align: center;
    margin-left: 40%;
    height: 2.2vw;
    color: white;
    background-color: #001d3a;
    /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); */
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: background-color, box-shadow;
}

.buttonRESET:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}

.buttonRESET:disabled {
    background-color: rgb(230, 230, 230);
    color: rgb(155, 155, 155);
    pointer-events: none;
}

.buttonRESET:disabled:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(155, 155, 155);
    pointer-events: none;
}


@media (max-width: 900px) {
    .FormGroupPC {
        width: calc(75% + 1vw);
    }
    .FormRowInput {
        font-size: 22px;
    }
    .buttonRESET {
        font-size: 2vw;
        height: 3vw;
        min-width: 25%;
        padding-bottom: 4.5%;
    }
}

