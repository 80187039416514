.alumnilist {
    margin: auto;
    padding-bottom: 1vw;
    padding-top: 0.01vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    width: calc(80% - 1vw);
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: #001d3acf;
    box-shadow: 0 1px 2px rgb(215, 215, 218), 0 2px 4px rgb(223, 223, 223),
        inset 0 1px 0 #f2f2f2;
    border-radius: 3px;
    background-color: white;
}
.alumniList {
    float: left;
    min-width: 96%;
    margin-right: 2%;
    margin-left: 2%;
}

.noAlum {
    font-size: 1.2vw;
    line-height: 1.2vw;
    color: rgb(150, 150, 150);
   
    text-align: left;
    margin-left: 5%;
    font-style: italic;
    
}

.alumnetbio {
    font-size: 1vw;
    line-height: 1.2vw;
    color: rgb(114, 114, 114);
    font-style: italic;
    text-align: left;
    margin-left: 3%;
    
}

.alumName{
    color: #001d3acf;
    margin: auto;
    font-size: 1.6vw;
    line-height: 2.1vw;
    float: left;
}

.alumGradYear {
    color: #001d3acf;
    margin: auto;
    font-size: 1.7vw;
    line-height: 2.1vw;
    float: right;
}

.alumMajor {
    color: black;
    margin: auto;
    font-size: 1.3vw;
    float: left;
    line-height: 1.7vw;
}

.alumEmployer{
    color: black;
    margin: auto;
    font-size: 1.3vw;
    float: right;
    line-height: 1.7vw;
}

.alumContact{
    text-align: center;
    font-size: 1.2vw;
}

.alumBreak{
    line-height: 3.1vw;
    font-size: 2.9vw;
    align-self: start;
}

@media screen and (max-width: 500px){
    .alumBreak{
        line-height: 0vw;
        height: 0vw;
    }
}
