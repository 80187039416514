.adminUsersList {
    margin-left: 2rem;
    margin-top: 2rem;
}

.adminUserCell {
    border: 1px solid black;
    width: 95%;
    height: 20px;
    padding: 0.5rem;
    display: flex;
}

.adminUserName {
    float: left;
    min-width: 25%;
}

.orderPageItem {
    float: left;

}
.adminActions {
    float: right;
}
.adminActionsADMIN {
    float: right;
    color: red;
}

h2{
    font-weight: normal;
    font-size: 15px;
}