@keyframes slideup {
    0% {
        background-image: url("../../Photos/Icons/sun_landing_page_animation.png");
        background-repeat: no-repeat;
        background-position: center;
        background-color:white;
        transform: translateY(-50%);
        transform: rotate(360deg);
    }
    5% {
        background-color:white;
    }

    12% {
        background-color:rgb(250, 250, 220);
    }
    21% {
        background-color:rgb(249, 231, 166);
    }
    24% {
        transform: rotate(180deg);
    }
    24% {
        transform: translateY(-50%);
    }
    26% {
        background-color: rgba(255, 255, 255, 0);
    }
    40% {
        background-color: rgba(255, 255, 255, 0);
    }
    48% {
        transform: translateY(-25%);
        transform: rotate(180deg);
        background-color: rgba(255, 255, 255, 0);
    }
    60% {
        background-color: rgba(255, 255, 255, 0);
    }
}

@keyframes trans {
    10% {
        background-color: rgba(255, 255, 255, 0);
    }
}

.awardsBox{
    margin-left: 18.5%;
    margin-right: 18.5%;
    font-size: 1.2vw;
    text-align: center;
    color: #3788d8;
}

#startupdiv_parent {
    max-width: 100%;
    width: 100%;
}

#startupdiv {
    position: absolute;

    align-content: center;
    align-self: center;
    background-position: center;

    height: 500vh;
    top: 0px;
    width: 100%;
    pointer-events: none;
    z-index: 2;
    max-width: 100%;

    animation: 9s ease-in-out 0s 1 slideup;
}

.MessageFromCalendar{
    display: flex;
    flex-direction: row;
}

.MessageFromPresident
{
    width: 100%;
    display: flex;
    padding-left: 6.5%;
    padding-right:6.5%;
    box-sizing: border-box;
    flex-direction: row;
}

.presidentPhoto {
    flex: 0.6;
    padding: 2%;
}

.presidentMessage {
    flex: 1.4;
    padding: 2%;
    font-family:'Poppins';
}

.presidentTitle
{
    margin-bottom: 1vh;
    color: gray;
    font-size:2vw;
}

.presidentSubMessage
{
    font-size: 1vw;
    line-height: 1.4em;
    color: #001d3a;
}

.presidentContactInfo{
    color: gray;
}

.introMessage
{
    font-family: 'Poppins';
    font-size: 2.4vw;
    margin-top: -1.5%;
    margin-bottom: -3%;
    font-weight: 415;
    margin-right: 18.5%;
    margin-left: 18.5%;
    color: gray;
}
.introSubMessage
{
    font-family: 'Poppins';
    font-size: 1.2vw;
    font-weight: normal;
    color: #001d3a;
}

.calendarMessage {
    margin-top: -1vh;
    font-family: 'Poppins';
    font-size: 2vw;
    margin-bottom: 0.1vw;
    font-weight: 420;
    margin-left: 18.5%;
    margin-right: 18.5%;
    color: gray;
}
.calendarSubMessage
{
    font-size: 1.1vw;
    font-family: 'Poppins';
    line-height: 2.4vh;
    display: block;
    margin-bottom: -1vh;
    color: #001d3a;
}



@media screen and (max-width: 1500px) {
    .introMessage
    {
        font-size: 2.45vw;
    }
    .introSubMessage
    {
        font-size: 1.4vw;
    }
    .calendarMessage
    {
        font-size: 1.95vw;
        line-height: 5vh;
    }
    .calendarSubMessage
    {
        font-size: 1.35vw;
    }
    .presidentTitle
    {
        font-size: 2.1vw;
    }
    .presidentSubMessage
    {
        font-size: 1.15vw;
    }

    .awardsBox{
        font-size: 1.4vw;
    }
}

@media screen and (max-width: 1100px) {
    .introMessage
    {
        font-size: 3.05vw;
        margin-left: 16%;
        margin-right: 16%;
    }
    .introSubMessage
    {
        font-size: 1.9vw;
    }
    .calendarMessage
    {
        font-size: 2.8vw;
        line-height: 4vh;
        margin-left: 16%;
        margin-right: 16%;
    }
    .calendarSubMessage
    {
        font-size: 1.75vw;
    }
    .presidentTitle
    {
        font-size: 2.2vw;
        margin-left: 16%;
        margin-right: 16%;
    }
    .presidentSubMessage
    {
        font-size: 1.9vw;
    }

    .awardsBox{
        margin-left: 16%;
        margin-right: 16%;
        font-size: 1.9vw;
    }
}

@media screen and (max-width: 760px) {
    .introMessage
    {
        font-size: 3.9vw;
        margin-left: 13%;
        margin-right: 13%;
    }
    .introSubMessage
    {
        font-size: 3.05vw;
    }
    .calendarMessage
    {
        font-size: 3.6vw;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 2%;
        line-height: 4.2vh;
    }
    .calendarSubMessage
    {
        font-size: 2.75vw;
    }

    .MessageFromPresident
    {
        flex-direction: column;
    }
    .presidentPhoto
    {
        padding-left: 30%;
        padding-right: 30%;
    }
    .presidentTitle
    {
        text-align: center;
        font-size: 3.6vw;
    }
    .presidentSubMessage
    {
        font-size: 2.75vw;
    }

    .awardsBox{
        margin-left: 10%;
        margin-right: 10%;
        font-size: 3.05vw;
    }
}

@media screen and (max-width: 550px) {
    .calendarMessage{
        line-height: 2vh;
    }
}