.contactTitle{
    text-align: center;
    font-size: 2vw;
    color: #001d3a;
}

.contactSocials{
    padding: '1vw';
    font-size: '3vw';
    color: 'black';
}

.contactForm{
    text-align: center;
    background-color: #333333;
    width: 40%;
    margin:auto;
    padding: 2vw;
    border-radius: 1vw;
}

.contactComponenet{
    padding: .5vw;
    font-family: 'Poppins', sans-serif;
}

.buttoncontact {
    margin: 0;
    position: absolute;
    left: 50%;
    margin-top: 1%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-family: 'Poppins', sans-serif;
    display: block;
    font-size: 1vw;
    width: 10%;
    height: 2.5vw;
    color: white;
    background-color: #001d3a;
    border-radius: .1vw;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: transform, background-color, box-shadow;
    text-align: center;
}

.addPoppins {
    font-family: 'Poppins', sans-serif;
}

.contactComponenet::placeholder {
    font-family: 'Poppins', sans-serif;
}

.contactComponenet::-webkit-input-placeholder {
    font-family: 'Poppins', sans-serif;
  }

.contactComponenet:-ms-input-placeholder {
    font-family: 'Poppins', sans-serif;
}

.contactComponenet:-moz-placeholder {
    font-family: 'Poppins', sans-serif;
}

.contactComponenet::-moz-placeholder {
    font-family: 'Poppins', sans-serif;
}

.contactInfo{
    width: 20vw;
    height: 1.8vw;
    border-radius: 1vw;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1vw;
    border: .1vw solid yellow;
}