

.signInForm{
    position: relative;
    width: 70%;
    overflow: hidden;
    padding-top: 100%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 3.8vh;
    margin-top: 3.8vh;
    border-style: solid;
    border-color: #001d3a;
}

.responsive-iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    
    border: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

}

.gmIntro
{
    font-family: 'Poppins';
    font-size: 2vw;
    margin-top: 6vh;
    font-weight: 415;
    color: #001d3a;
    line-height: 1.5vw;
}

.gmSun {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    padding: 1vw;
    margin-top: 2vw;
  }

.gmSlideButton{
    background-color: rgba(254, 254, 84, 0.892);
    color: black;
    text-decoration: none;
    text-align: center;
    border-radius: 15px;
    border: none;
    display: inline-block;
    padding: 15px 15px;
    font-size: 20px;
    margin: 20px;
    cursor: pointer;
    font-family: Poppins;
}

.gmSlideButton:hover(){
    cursor: pointer;
}

.gmSlides{
    margin-left: 15%;
    margin-right: 15%;
    align-self: center;
    text-align: center;
}



@media screen and (max-width: 800px) {
    .signInForm{
        width: 90%;
        margin-left: 5%;
        margin-left: 5%;
        border-width: medium;
    }

    .gmSlides{
        margin-left: 5%;
        margin-left: 5%;
    }

    .gmIntro {
        font-size: 4vw;
    }
    .gmSun {
        width: 20%;
        padding-bottom: 5%;
    }
}