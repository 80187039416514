.navgap {
    margin-top: 115px;
}

.hsignin {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: -2vw;
    color: #001d3a;
    padding-top: 3vw;
    margin-top: 3vw;
}

.signinlinks {
    margin: auto;
    margin-top: 0.0vw;
    padding-top: 0%;
    text-align: center;
    width: 50%;
    border-radius: 1.2vw;
    font-family: 'Poppins', sans-serif;
}