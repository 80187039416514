@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.navTop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 102px;
    padding: 15px 60px;
    box-sizing: border-box;
    transition: 0.8s;
    -webkit-transition: 0.8s;
    overflow: hidden;
    z-index: 4;  /*was 4*/
}

.navElse {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 115px;
    padding: 15px 60px;
    box-sizing: border-box;
    transition: 0.6s;
    -webkit-transition: 0.6s;
    background-color: white;
    box-shadow: 0vw 0.01vw 1.3vw -0.75vw rgba(0, 0, 0, 0.865);
    overflow: hidden;
    z-index: 4;
}

.navTop .logo {
    position: absolute;
    float: left;
}
.navElse .logo {
    position: absolute;
    float: left;
}

.navTop .logo img {
    height: 80px;
    padding-top: 3px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    margin-left: -40px;
}
.navElse .logo img {
    height: 80px;
    padding-top: 3px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    margin-left: -40px;
}

.navTop .ulNav {
    float: right;
    margin: 0;
    margin-top: 16px;
    padding: 0;
    display: flex;
    margin-right: -30px;
}
.navElse .ulNav {
    float: right;
    margin: 0;
    margin-top: 16px;
    padding: 0;
    display: flex;
    margin-right: -30px;
}

.navTop .ulNav .liNav {
    list-style: none;
    line-height: 36px;
    padding: 10px 0.3vw;
    margin-left: 16px;
    text-decoration: none;
    transition: 0.5s;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    border-radius: 50px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}
.navElse .ulNav .liNav {
    list-style: none;
    line-height: 36px;
    padding: 10px 0.3vw;
    margin-left: 16px;
    text-decoration: none;
    transition: 0.5s;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    border-radius: 50px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.navTop .ulNav .liNav:hover {
    background: rgba(254, 254, 84, 0.892);
    border-radius: 100px;
    font-size: 18px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}
.navElse .ulNav .liNav:hover {
    background: rgba(254, 254, 84, 0.892);
    border-radius: 100px;
    font-size: 18px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
}

.signin {
    list-style: none;
    line-height: 36px;
    padding: 10px 0.3vw;
    margin-left: 16px;
    text-decoration: none;
    transition: 0.5s;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    border-radius: 50px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    margin-left: 22px;
    background:  rgba(254, 254, 84, 0.892);
}

.checkbtn {
    font-size: 30px;
    color: white;
    float: right;
    line-height: 80px;
    height: 40px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
    color: rgb(255, 255, 128);
}

.iEq {
    color: black;
    font-style: normal;
    font-size: 60px;
}

#check {
    display: none;
}

/* For mobile and small devices */
@media (max-width: 1500px) {

    .navTop .logo img {
        height: 30px;
        padding-top: 30px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        margin-left: -40px;
    }
    .navElse .logo img {
        height: 30px;
        padding-top: 30px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        margin-left: -40px;
    }
    .checkbtn {
        display: block;
    }
    .navTop {
        color: white;
        z-index: 3; /*1*/
    }
    .navElse {
        color: white;
        z-index: 3; /*1*/
    }
    .navTop .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: -100%;
        align-items: center;
        display: block;
        text-align: center;
        
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
    }
    .navElse .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: -100%;
        align-items: center;
        display: block;
        text-align: center;
        /*
        transition: all 0.5s;
        -webkit-transition: all 0.5s;*/
    }
    .navTop .ulNav .liNav {
        font-size: 1.8rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        margin-left: 0;
    }
    .navElse .ulNav .liNav {
        font-size: 1.8rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        margin-left: 0;
        position: relative;
    }
    .navTop .ulNav .liNav:hover {
        font-size: 1.9rem;
    }
    .navElse .ulNav .liNav:hover {
        font-size: 1.9rem;
    }
    .navElse {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 115px;
        padding: 15px 60px;
        box-sizing: border-box;
        background-color: white;
    }
    .navTop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 115px;
        padding: 15px 60px;
        box-sizing: border-box;
        background-color: white;
    }
    .signin {
        list-style: none;
        line-height: 36px;
        padding: 10px 0.3vw;
        margin-left: 16px;
        text-decoration: none;
        transition: 0.5s;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        border-radius: 50px;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        font-size: 1.8rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        margin-left: 0;
        margin-left: 0em;
        color: black;
    }
    .centered {
        color: red;
    }
    #check:checked ~ .ulNav {
        left: 0;
        -webkit-text-stroke-width: 0.0px;
    }


}


@media (max-width: 600px) {
    .navTop .logo img {
        height: 40px;
        padding-top: 27px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
    }
    .navElse .logo img {
        height: 40px;
        padding-top: 27px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
    }
    .iEq {
        color: black;
        font-style: normal;
        font-size: 40px;
        margin-right: -50px;
        margin-left: 50px;
    }
    
    
    #check:checked ~ .ulNav {
        left: 0;
        -webkit-text-stroke-width: 0.0px;
    }
}


@media (max-width: 300px) {
    .navTop .logo img {
        height: 25px;
        padding-top: 25px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        margin-left: -40px;
    }
    .navElse .logo img {
        height: 25px;
        padding-top: 25px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        margin-left: -40px;
    }
    
    .iEq {
        color: black;
        font-style: normal;
        font-size: 60px;
        margin-right: -30px;
    }

    .navTop .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: -100%;
        align-items: center;
        display: block;
        text-align: center;
        /*
        transition: all 0.5s;
        -webkit-transition: all 0.5s;*/
    }
    .navElse .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: -100%;
        align-items: center;
        display: block;
        text-align: center;
        /*
        transition: all 0.5s;
        -webkit-transition: all 0.5s;*/
    }
}

/*
@media only screen and (max-width: 600px) {
    .iEq {
        color: black;
        font-style: normal;
        font-size: 60px;
    }
    .navTop .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: -100%;
        align-items: center;
        display: block;
        text-align: center;
    }
    .navElse .ulNav {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: white;
        top: 97px;
        left: 0%;
        align-items: center;
        display: block;
        text-align: center;
    }
    .navTop .ulNav {
        left: 0%;
    }
}
*/
.container {
    text-align: center;
    background-image: url(/static/media/royce2.53e11a2e.jpeg);

    /* Set a specific height */
    width: 100%;
    height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;

    z-index: -3;
}

.parallax {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    object-fit: cover;
}

.centered {
    position: absolute;
    margin-top: 19.5%;
    margin-top: calc(40vh);
    /*font-family: Georgia,Times,Times New Roman,serif; */
    font-family: Poppins;
    font-family: Georgia,Times,Times New Roman,serif;
    font-family: Poppins;
    font-size: 4.5vw;
    width: 100%;
    color: white;
    transition: 0.3s;
    letter-spacing: 0.04rem;
    z-index: 0;

    pointer-events: none;

    -webkit-text-stroke-width: 0.001vw;
    -webkit-text-stroke-color: white;

}

.scrolled {
    position: absolute;
    margin-top: 57vh;
    font-family: Montserrat;
    font-size: 4.4vw;
    font-family: Georgia,Times,Times New Roman,serif;
    font-family: 'Poppins', sans-serif;
    font-size: 4.2vw;
    width: 100%;
    color: rgba(255, 255, 255, 0);
    transition: 0.5s;
    pointer-events: none;

    perspective: 100px;

    -webkit-text-stroke-width: 0.0px;
    -webkit-text-stroke-color: white;
}

.HRcentered {
    border: 0.33vw solid #001d3a;
    width: 52%;
    transition: 0.15s;
    margin-top: 1%;
    z-index: 0;
}

.HRscrolled {
    border: 0 solid #001d3a;
    width: 45%;
    transition: 0.15s;
}

.bottom {
    margin-top: -0.5%;
    font-size: 3.3vw;
    transition: ease 0.0s;

    -webkit-text-stroke-width: 0.0vw;
    -webkit-text-stroke-color: white;
}

.bottomscrolled {
    font-size: 2.8vw;
    transition: ease 0.2s;
    margin-top: 3%;
    color: rgba(255, 255, 255, 0);
    letter-spacing: 0.1rem;

}

.container3 {
    text-align: center;

    /* Set a specific height */
    width: 100%;
    height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;

    z-index: -3;
}


@media (max-width: 650px) {
    .centered {
        margin-top: 46vh;
    }
}

.footer {
    width: 100%;
    height: max-content;
    background-color:#001d3a;
    z-index: 1;
    font-family: 'Poppins', sans-serif;
}

.footerTitle{
    color: white;
    text-align: center;
    font-size: 1.5vw;
    padding-top: 1vw;
}

.footerSocials{
    text-align: right;
    padding: '1vw',
}

.gradient-line-hor {
    margin: auto;
    margin-top: 0.8vw;
    height: .4vw;
    width: 100%;
    background: linear-gradient(to right,#fd662f 0,#fd662f 9%,#72a8be 9%,#72a8be 22%,#0070c0 22%,#0070c0 50%,#9ee0f9 50%,#9ee0f9 100%);
}

.footerItems{
    width: 80%;
    margin: auto;
}

.footerTopInfo{
    color: white;
    font-size: '1vw';
    padding: '1vw';
    margin-top: -1vw;
}

.footerColumns {
    display: flex;
    justify-content: space-around; /* Adjust this as needed */
 }
 
 .footerColumn {
    flex: 1 1;
    margin: 0 1vw; /* Adjust the margin between columns */
    text-align: center;
    justify-content: center;
 }
 
@charset "UTF-8";


html, body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  height:100%
}

h2 {
  margin: 0;
  font-size: 16px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b { /*used for event dates/times*/
  margin-right: 3px;
}


.fc .fc-button-primary {
  color: black;
  color: var(--fc-button-text-color, black);
  background-color: rgb(254 254 84 / 89%);
  background-color: var(--fc-button-bg-color, rgb(254 254 84 / 89%));
  border-color: white;
  border-color: var(--fc-button-border-color, white);
  border-width: medium;
  border-radius: 15px;
  font-family: 'Poppins',sans-serif;
}

.fc .fc-toolbar-title {
  font-size: 1.75em;
  margin: 0;
  color: rgb(0, 29, 58);
  font-family: 'Poppins', sans-serif
}

.fc-h-event .fc-event-title {
  display: inline-block;
  vertical-align: top; 
  left: 0;  
  right: 0; 
  max-width: 100%; 
  overflow: hidden;
  font-family: 'Poppins',sans-serif;
}

.demo-app {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  min-height: 100%;
  font-family: 'Poppins',Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0 7.5% 0 7.5%;
}
.top{
  flex: 0.3 1;
  line-height: 1.3;
  margin-top: 20px;
  /*
  margin-left: 18%;
  width: 32%;*/
  justify-content: space-between;
  /*align-items: flex-start;*/
}

.cal-event-bar{
  border-color: rgb(0, 29, 58);
  border-style: solid;
  border-radius: 15px;
  /*border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;*/
  border-width: medium;
  color: white;
  background-color: rgb(0, 29, 58);
  margin-top: 80px;
}

.bottom-calendar-main {
  padding: 3em;
  align-items: center;
  justify-content: center;
  z-index: 2;
  flex: 1 1;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

.titleEventClicked{
  font-size: 1.2vw;
}

.otherInfo{
  font-size: 0.9vw;
}


@media screen and (max-width: 1200px)
{
  .demo-app{
    flex-direction: column;
  }

  .top{
    margin-top: -50px;
    margin-left: 8%;    
    width: max-content;
    /*padding: 0px 20px 0 40px;*/
  }

  .titleEventClicked{
    padding-right: 20px;
  }

  .otherInfo{
    padding-right: 20px;
  }

  .bottom-calendar-main{
    margin-left: 4%;
    margin-right: 4%;
  }

  .titleEventClicked{
    font-size: 1.8vw;
  }

  .otherInfo{
    font-size: 1.4vw;
  }
}

@media screen and (max-width: 800px) {
  .top{
    padding-top: 0px;
    margin-left: 4%;
    line-height: 1;
  }

  .bottom-calendar-main{
    margin-left: -2%;
    margin-right: -2%;
  }

  .titleEventClicked{
    font-size: 2.6vw;
  }

  .otherInfo{
    font-size: 2.4vw;
  }
}
@keyframes slideup {
    0% {
        background-image: url(/static/media/sun_landing_page_animation.5712740d.png);
        background-repeat: no-repeat;
        background-position: center;
        background-color:white;
        transform: translateY(-50%);
        transform: rotate(360deg);
    }
    5% {
        background-color:white;
    }

    12% {
        background-color:rgb(250, 250, 220);
    }
    21% {
        background-color:rgb(249, 231, 166);
    }
    24% {
        transform: rotate(180deg);
    }
    24% {
        transform: translateY(-50%);
    }
    26% {
        background-color: rgba(255, 255, 255, 0);
    }
    40% {
        background-color: rgba(255, 255, 255, 0);
    }
    48% {
        transform: translateY(-25%);
        transform: rotate(180deg);
        background-color: rgba(255, 255, 255, 0);
    }
    60% {
        background-color: rgba(255, 255, 255, 0);
    }
}

@keyframes trans {
    10% {
        background-color: rgba(255, 255, 255, 0);
    }
}

.awardsBox{
    margin-left: 18.5%;
    margin-right: 18.5%;
    font-size: 1.2vw;
    text-align: center;
    color: #3788d8;
}

#startupdiv_parent {
    max-width: 100%;
    width: 100%;
}

#startupdiv {
    position: absolute;

    align-content: center;
    align-self: center;
    background-position: center;

    height: 500vh;
    top: 0px;
    width: 100%;
    pointer-events: none;
    z-index: 2;
    max-width: 100%;

    animation: 9s ease-in-out 0s 1 slideup;
}

.MessageFromCalendar{
    display: flex;
    flex-direction: row;
}

.MessageFromPresident
{
    width: 100%;
    display: flex;
    padding-left: 6.5%;
    padding-right:6.5%;
    box-sizing: border-box;
    flex-direction: row;
}

.presidentPhoto {
    flex: 0.6 1;
    padding: 2%;
}

.presidentMessage {
    flex: 1.4 1;
    padding: 2%;
    font-family:'Poppins';
}

.presidentTitle
{
    margin-bottom: 1vh;
    color: gray;
    font-size:2vw;
}

.presidentSubMessage
{
    font-size: 1vw;
    line-height: 1.4em;
    color: #001d3a;
}

.presidentContactInfo{
    color: gray;
}

.introMessage
{
    font-family: 'Poppins';
    font-size: 2.4vw;
    margin-top: -1.5%;
    margin-bottom: -3%;
    font-weight: 415;
    margin-right: 18.5%;
    margin-left: 18.5%;
    color: gray;
}
.introSubMessage
{
    font-family: 'Poppins';
    font-size: 1.2vw;
    font-weight: normal;
    color: #001d3a;
}

.calendarMessage {
    margin-top: -1vh;
    font-family: 'Poppins';
    font-size: 2vw;
    margin-bottom: 0.1vw;
    font-weight: 420;
    margin-left: 18.5%;
    margin-right: 18.5%;
    color: gray;
}
.calendarSubMessage
{
    font-size: 1.1vw;
    font-family: 'Poppins';
    line-height: 2.4vh;
    display: block;
    margin-bottom: -1vh;
    color: #001d3a;
}



@media screen and (max-width: 1500px) {
    .introMessage
    {
        font-size: 2.45vw;
    }
    .introSubMessage
    {
        font-size: 1.4vw;
    }
    .calendarMessage
    {
        font-size: 1.95vw;
        line-height: 5vh;
    }
    .calendarSubMessage
    {
        font-size: 1.35vw;
    }
    .presidentTitle
    {
        font-size: 2.1vw;
    }
    .presidentSubMessage
    {
        font-size: 1.15vw;
    }

    .awardsBox{
        font-size: 1.4vw;
    }
}

@media screen and (max-width: 1100px) {
    .introMessage
    {
        font-size: 3.05vw;
        margin-left: 16%;
        margin-right: 16%;
    }
    .introSubMessage
    {
        font-size: 1.9vw;
    }
    .calendarMessage
    {
        font-size: 2.8vw;
        line-height: 4vh;
        margin-left: 16%;
        margin-right: 16%;
    }
    .calendarSubMessage
    {
        font-size: 1.75vw;
    }
    .presidentTitle
    {
        font-size: 2.2vw;
        margin-left: 16%;
        margin-right: 16%;
    }
    .presidentSubMessage
    {
        font-size: 1.9vw;
    }

    .awardsBox{
        margin-left: 16%;
        margin-right: 16%;
        font-size: 1.9vw;
    }
}

@media screen and (max-width: 760px) {
    .introMessage
    {
        font-size: 3.9vw;
        margin-left: 13%;
        margin-right: 13%;
    }
    .introSubMessage
    {
        font-size: 3.05vw;
    }
    .calendarMessage
    {
        font-size: 3.6vw;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 2%;
        line-height: 4.2vh;
    }
    .calendarSubMessage
    {
        font-size: 2.75vw;
    }

    .MessageFromPresident
    {
        flex-direction: column;
    }
    .presidentPhoto
    {
        padding-left: 30%;
        padding-right: 30%;
    }
    .presidentTitle
    {
        text-align: center;
        font-size: 3.6vw;
    }
    .presidentSubMessage
    {
        font-size: 2.75vw;
    }

    .awardsBox{
        margin-left: 10%;
        margin-right: 10%;
        font-size: 3.05vw;
    }
}

@media screen and (max-width: 550px) {
    .calendarMessage{
        line-height: 2vh;
    }
}
.suform {
    margin: auto;
    margin-top: 3vw;
    padding-top: 3%;
    width: 50%;
    background-color: rgba(245, 245, 245, 0.954); /* yellow: rgba(255, 255, 213, 0.954);  gray: rgba(245, 245, 245, 0.954) */
    border-radius: 1.2vw;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 1px 2px rgb(215, 215, 218), 0 2px 4px rgb(223, 223, 223),
    inset 0 1px 0 #f2f2f2;
}

.suh1 {
    text-align: center;
    margin-top: 60px;
    font-family: 'Poppins', sans-serif;
    font-size: 7vw;
    padding-top: 3.9vw;
    letter-spacing: 0.15vw;
    color: whitesmoke;
    -webkit-text-stroke-width: 0.01rem;
    -webkit-text-stroke-color: whitesmoke;
}

.newletterForm {
    width: 100%;
    height: 740px;
}

.photoback {
    position: absolute;
    z-index: -1;
}

.photobackphoto {
    width: 100%;
}

.FormRow {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.FormRow:first-child {
    border-top: none;
}

.FormRowLabel {
    min-width: 26%;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 1%;
    font-size: 1.2vw;
    color: rgb(150, 150, 150);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.FormRowLabelDropDown {
    min-width: 49.5%;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 1%;
    font-size: 1.2vw;
    color: rgb(150, 150, 150);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #001d3a;
    font-family: 'Poppins', sans-serif;
    /* Hack to hide the default webkit autofill */
    -webkit-transition: background-color 100000000s;
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
  }
  
.FormRowInput {
    font-size: 1.2vw;
    width: 80%;
    padding-top: 0.0%;
    color: #001d3a;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    animation: 1ms void-animation-out;
    
}

.FormRowInput2 {
    font-size: 1.2vw;
    width: 80%;
    padding-top: 0.0%;
    color: #001d3a;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    animation: 1ms void-animation-out;
    border: 0;
}

.graydd {
    font-size: 1.2vw;
    width: 55%;
    padding-top: 0.0%;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    animation: 1ms void-animation-out;
    color: #001d3a17;
}

.FormRowInput::placeholder {
    color: #001d3a17;
}

.FormGroup {
    margin: auto;
    width: 87%;
    padding: 0;
    border-style: none;
    background-color: #ffffff;
    color: cornsilk;
    box-shadow: 0 1px 2px rgb(215, 215, 218), 0 2px 4px rgb(223, 223, 223),
        inset 0 1px 0 #f2f2f2;
    border-radius: 4px;
}

.formlabelsu {
    color: rgb(150, 150, 150);
    font-family: 'Poppins', sans-serif;
}

.formcontrolsu {
    color: #001d3a;
    font-family: 'Poppins', sans-serif;
}

.randomtop {
    margin-top: 25px;
}

.disclaimer1 {
    font-size: 0.8vw;
    color: rgb(150, 150, 150);
    margin-left: 11.5%;
    margin-right: 5.5%;
    margin-top: -0.2%;
    font-style: italic;
}

.disclaimer2 {
    font-size: 0.8vw;
    color: rgb(150, 150, 150);
    margin-left: 11.5%;
    margin-right: 5.5%;
    margin-top: 0.4%;
    margin-bottom: 0%;
    font-style: italic;
}

.submitNotice {
    font-size: 0.8vw;
    color: rgb(150, 150, 150);
    margin-top: 2%;
    margin-bottom: 0%;
    font-style: italic;
    text-align: center;
}

.checksu {
    padding-bottom: 1%;
}

.checkboxsu {
    margin-left: 4%;
    margin-right: 3%;
    background: #001d3a;
}

.checkboxsu:checked:after {
    background: #001d3a;
}

.checklabelsu {
    font-size: 1.1vw;
    width: 87%;
    padding: 0;
    margin-top: -5%;
    color: #001d3a;
}

.membergrid {
    margin: auto;
    width: 50%;
    padding: 0;
    padding-top: 4%;
    padding-left: 10%;
    color: black;
}

.newslettersu {
    margin: 0;
    margin-left: 25%;
    margin-right: 2.5%;
}

.newsletterDiv {
    text-align: center;
    padding-top: 2%;
    padding-bottom: -2%;
}

.newsletterlabelsu {
    font-size: 1.1vw;
    width: 87%;
    padding: 0;
    margin-top: -5%;
    color: #001d3a;
    vertical-align: middle;
}

.buttonsu {
    margin: 0;
    margin-top: -2vw;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Poppins', sans-serif;
    display: block;
    font-size: 16px;
    width: 20%;
    height: 40px;
    color: white;
    background-color: #001d3a;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.buttonsu:disabled {
    background-color: rgb(230, 230, 230);
    color: rgb(155, 155, 155);
    pointer-events: none;
}
.buttonsu:disabled:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(155, 155, 155);
    pointer-events: none;
}

.buttonsu:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}






@media only screen and (max-width: 600px) {
    .suform {
        width: 90%;
    }

    .FormRowLabel {
        font-size: 2.8vw;
        margin-right: 8%;
    }

    .FormRowInput {
        
        margin-top: 3vw;
    }

    .FormGroup {
        font-size: 2.8vw;
    }

    .FormRowInput::placeholder {
        color: #001d3a17;
        font-size: 2.8vw;
    }

    .disclaimer1 {
        font-size: 1.9vw;
        margin-top: 0%;
    }

    .FormRowLabelDropDown {
        font-size: 2.2vw;
    }

    .graydd {
        font-size: 2.8vw;
    }

    .FormRowInput2 {
        font-size: 2.8vw;
        border: 0;
    }

    .FormRowInput2::placeholder {
        color: #001d3a23;
    }
    
    .newsletterlabelsu {
        font-size: 2.5vw;
        width: 50%;

    }
    .newslettersu {
        margin-left: 16%;
    }

    .submitNotice {
        font-size: 2vw;
        margin-top: 9%;
    }

    .buttonsu {
        font-size: 50%;
        text-align: center;
    }

    .checklabelsu {
        font-size: 2vw;
    }

    .checksu {
        margin-top: 1.5%;
        margin-bottom: 1.5%;
    }

}

.FormRowInput,
.FormRowInput2,
.FormRowInputViewTests,
.graydd,
.buttonsu,
.TutorPageButton,
.TutorStudentPageButton,
.buttonSlider,
.buttonADD,
.buttonRESET,
.formcontrolsu,
.formlabelsu {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}
.navgap {
    margin-top: 115px;
}

.hsignin {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: -2vw;
    color: #001d3a;
    padding-top: 3vw;
    margin-top: 3vw;
}

.signinlinks {
    margin: auto;
    margin-top: 0.0vw;
    padding-top: 0%;
    text-align: center;
    width: 50%;
    border-radius: 1.2vw;
    font-family: 'Poppins', sans-serif;
}
.adminUsersList {
    margin-left: 2rem;
    margin-top: 2rem;
}

.adminUserCell {
    border: 1px solid black;
    width: 95%;
    height: 20px;
    padding: 0.5rem;
    display: flex;
}

.adminUserName {
    float: left;
    min-width: 25%;
}

.orderPageItem {
    float: left;

}
.adminActions {
    float: right;
}
.adminActionsADMIN {
    float: right;
    color: red;
}

h2{
    font-weight: normal;
    font-size: 15px;
}
.container2 {
    text-align: left;

    width: 100%;
    height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;
    max-width: 100%;
}

.parallax2 {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    object-fit: cover;
}

.centered2 {
    position: absolute;
    margin-top: 34%;
    font-family: Poppins;
    font-size: 6.5vw;
    letter-spacing: 0.05rem;
    width: 100%;
    color: white;
    transition: 0.3s;
    pointer-events: none;
    -webkit-text-stroke-width: 0.25vw;
    -webkit-text-stroke-color: white;
}

.scrolled2 {
    position: absolute;
    margin-top: 34%;
    /* font-family: Georgia,Times,Times New Roman,serif; */
    font-family: Poppins;
    font-size: 6.2vw;
    width: 100%;
    color: rgba(255, 255, 255, 0);
    transition: 0.3s;
    pointer-events: none;
    -webkit-text-stroke-width: 0.0px;
}


@media screen and (max-width: 800px) {
    .centered2 {
        margin-top: 130%;
        font-size: 9vw;
    }
}
.MissionVision {
    display: flex;
    padding-top: 8vh;
    padding-bottom: 8vh;
    font-family: Poppins;
    width: 90%;
    margin-left: 8vw;
}

.historyTitle {
    margin-top: 0.1vw;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 3vw;
    margin-bottom: 3.1vw;
    color: #001d3a;
    padding-top: 3vw;
}

.missionVisionDescription {
    font-size: 1.2vw;
    color: gray;
    margin-left: 10%;
}

.mvBlock {
    font-size: 2.4vw;
    color: #001d3a;
    width: 45%;
}

.aboutUsAward {
    font-size: 3.2vw;
    color: '#001d3a';
    margin-bottom: 0.5vh;
    margin-top: 4vh;
    text-align: center;
}

.awardName {
    font-size: 2vw; 
    color: gray;
    margin-bottom: 0.4vw;
    text-align: center;
}

.awardDescription {
    font-size: 1.1vw;
    text-align: center; 
    color: #001d3a; 
    margin-left: 30%;
    margin-right: 30%;
}



/* Phones */
@media screen and (max-width: 600px) {
    .MissionVision {
        font-size: 20.5vw;
        display: grid;
        width: 100%;
        padding-top: 4vh;
        padding-bottom: 4vh;
    }
    .historyTitle{
        font-size: 7vw;
        padding-top: 8vw;
    }
    .mvBlock {
        margin-left: 2%;
        min-width: 80%;
        font-size: 5vw;
        padding-top: 1vw;
    }
    .missionVisionDescription{
        font-size: 3.3vw;
        padding-bottom: 2vw;
    }
    .aboutUsAward {
        font-size: 7vw;
    }  
    .awardName {
        font-size: 3.5vw;
    }
    .awardDescription {
        font-size: 2.5vw;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
}
#rightsun {
    height: 10%;
    width: 4%;
    margin-left: 0%;
    margin-top: -5.4%;
    align-self: auto;
}

#rightsunLanding {
    height: 10%;
    width: 4%;
    margin-left: 0%;
    margin-top: -5%;
    align-self: auto;
}

#rightsunMerch {
    height: 10%;
    width: 4%;
    margin-left: 0%;
    margin-top: -25vh;
    align-self: auto;
}

#leftsun {
    height: 10%;
    width: 4%;
    margin-top: -5.4%;
    margin-left: 92%;
}

#leftsunLanding {
    height: 10%;
    width: 4%;
    margin-top: -5%;
    margin-left: 92%;
}

#leftsunMerch {
    height: 10%;
    width: 4%;
    margin-top: -25vh;
    margin-left: 92%;
}

.minisun {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 4%;
}

.initIntro {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    margin-top: -1%;
    margin-bottom: -3.4%;
    margin-right: 10%;
    margin-left: 10%;
}

.introBar {
    width: 50%;
    border: 0.1vw solid #001d3a;
    margin-top: 3%;
    margin-bottom: 3%;
}

.containerInititiatives {
    display: flex;
    justify-content: center;
    margin-left: 5%;
}

.h1init {
    margin-top: 0.1vw;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 3vw;
    margin-bottom: 3.1vw;
    color: #001d3a;
}

.picture-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
    justify-items: center;
    grid-column-gap: 2vw;
    grid-row-gap: 9.5vw;
    margin-right: 9%;
    margin-left: 9%;
    margin-bottom: 13vw;
    justify-content: center;
}

#imgA {
    width: 75%;
    border-radius: 3%;
    position: unset;
    transition: ease 0.15s;
    box-shadow: 0vw 0vw 0.1vw 0.005vw rgba(0, 0, 0, 0.413);
}

#initTitle {
    margin-top: -28%;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 2.1vw;
    transition: ease 0.275s;

    -webkit-text-stroke-width: 0.04rem;
    -webkit-text-stroke-color: white;

    pointer-events: none;
}

#description {
    margin-top: -17.5%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1vw;
    color: rgba(255, 255, 255, 0);
    transition: ease 0.2s;

    pointer-events: none;
}



/* Responsive */



#imgA:hover {
    width: 75.75%;
    transition:  0.15s;
    box-shadow: 0vw 0vw 0.1vw 0.15vw rgba(250, 250, 72, 0.201);
}

#imgA:hover + #initTitle {
    color: rgba(255, 255, 255, 0);
    transition: ease 0.6s;
    -webkit-text-stroke-width: 0.00vw;
    -webkit-text-stroke-color: rgba(255, 255, 255, 0);
}

#imgA:hover ~ #description {
    color: #001d3acb;
    transition: ease 0.6s;
}




.eBoardPage {
  width: 80%;
  margin: 30px auto;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-wrap: wrap;
}

.sponsorPage {
  width: 80%;
  margin: 30px auto;
  font-family: 'Poppins', sans-serif;
  flex-wrap: wrap;
  text-align: center;
}

.eBoardPerson {
  display: inline-block;
  margin-bottom: 8px;
  width: calc(33% - 6px);
  margin-right: 8px;
  text-decoration: none;
  color: black;
  text-align: center;
}

.eBoardPerson3 {
  display: inline-block;
  margin-bottom: 8px;
  width: calc(33% - 6px);
  margin-right: 4px;
  text-decoration: none;
  color: black;
  text-align: center;
  margin-left: 4px;
}

.eBoardPerson:nth-of-type(2n) {
  margin-right: 0;
}


@media screen and (max-width: 1050px){
.eBoardPerson {
  display: inline-block;
  margin-bottom: 8px;
  width:20vw;
  width: calc(50% - 4px);
  margin-right: 8px;
  text-decoration: none;
  color: black;
  text-align: center;
}
}

@media screen and (max-width: 700px){
.eBoardPerson {
  display: inline-block;
  margin: 1vw;
  width: calc(100% - 4px);
  text-align: center;
  text-decoration: none;
  color: black;
}
.eBoardPerson3 {
  display: inline-block;
  margin: 1vw;
  width: calc(100% - 4px);
  text-align: center;
  text-decoration: none;
  color: black;
}

}

.eBoardPerson2 {
  margin: 0;
  overflow: hidden;
}

.setFontSizeName {
  font-family: 'Poppins', sans-serif;
  font-size: 1.8vw;
  text-align: match-parent;
  line-height: 1.4vw;
  font-weight: 'bold';
  color: #001d3a;
  margin-top: 1.1vw;
}

.setFontSizePosition{
  font-family: 'Poppins', sans-serif;
  font-size: 1.2vw;
  line-height: 1vw;
  text-align: match-parent;
  margin-top: 1.2vw;

}

.setFontSizeLevel{
font-family: 'Poppins', sans-serif;
font-size: 1.2vw;
line-height: 2vw;
text-align: match-parent;
margin-top: 1.2vw;

}

.setFontSizeStaffEmail{
font-family: 'Poppins', sans-serif;
font-size: 1.2vw;
line-height: 1vw;
text-align: match-parent;
margin: 1.2vw;

}

.BoardPageImages {
  border: .1vw solid #001d3a;
  width:  20vw;
  height: 20vw;
  display: block;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
}

.SponsorPageImages {
  width:  13vw;
  height: 13vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
}

.SponsorBronzeImages {
  width:  29vw;
  height: 30vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
}

.SponsorSilverImages {
  width:  29vw;
  height: 39vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
}

.SponsorGoldImages {
  width:  29vw;
  height: 47vw;
  display: block;
  margin-left: auto;
  /* margin-left: 10vw; */
  margin-right: auto;
  background-color: black;
  
}

.flip-card {
  overflow: hidden;
  margin: 1vw auto;
  background-color: transparent;
  width: 20.6vw;
  height: 20.6vw;
}



/* This container is needed to position the front and back side */
.flip-card-inner{
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  z-index: -1;
}



/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner{
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  width: 20.3vw;
}



.backSideText{
  font-family: 'Poppins', sans-serif;
  font-size: 1.8vw;
  line-height: 2vw;
  color: white;
  margin: .5vw;
}

.backSideTextSmall{
  font-family: 'Poppins', sans-serif;
  font-size: 1.1vw;
  line-height: 1.2vw;
  color: white;
  margin: .5vw;
}

.majorFont{
  font-family: 'Poppins', sans-serif;
  font-size: 1.3vw;
  line-height: 1.2vw;
  margin: .4vw;
  color: yellow;
}

.sponsorLevels{
  font-family: 'Poppins', sans-serif;
  font-size: 3vw;
  line-height: 3.5vw;
  margin: .4vw;  
  position: relative;
  color: black;
  padding-top: 2vw;
  transition: 1s;
}

.sponsorLevelsNoColor{
  font-family: 'Poppins', sans-serif;
  font-size: 3vw;
  line-height: 3.5vw;
  margin: .4vw;  
  position: relative;
  color: rgba(0, 0, 0, 0);
  padding-top: 2vw;
  transition: 0.5s;
}

.majorFontLong{
  font-family: 'Poppins', sans-serif;
  font-size: 1.16vw;
  line-height: 1.2vw;
  margin: .4vw;
  color: yellow;
}

.emailFont{
  font-family: 'Poppins', sans-serif;
  font-size: 1vw;
  line-height: 1.2vw;
  position: absolute;
  text-align: center;
  bottom: .4vw;
  color: yellow;
  margin-left: 0.8vw;
  white-space: pre-wrap;
}

.emailFontShort{
  font-family: 'Poppins', sans-serif;
  font-size: 1vw;
  line-height: 1.2vw;
  position: absolute;
  text-align: center;
  bottom: .4vw;
  color: yellow;
  margin-left: 4vw;
}

.emailFontLong{
  font-family: 'Poppins', sans-serif;
  font-size: 1vw;
  line-height: 1.2vw;
  position: absolute;
  text-align: center;
  bottom: .4vw;
  color: yellow;
  margin-left: .8vw;
}

/* Style the back side */
.flip-card-back {
  background-color: #001d3aa0;
  transform: rotateY(180deg);
  justify-content: center;
  align-items: center;
  text-align: center;
}

.titleFont1{
  font-family: 'Poppins', sans-serif;
  font-size: 1.5vw;
  line-height: 1.8vw;
  text-align: center;
  margin: 1.2vw;
  padding: 1vw;
  
}

.introFont{
font-family: 'Poppins', sans-serif;
font-size: 1.5vw;
line-height: 1.8vw;
position: absolute;
margin: 1.2vw;
}

.titleFont2{
  font-family: 'Poppins', sans-serif;
  font-size: 1.2vw;
  line-height: 1.8vw;
  text-align: center;
  color: gray;
  font-style: none;
  margin-left: 1.2vw;
  margin-right: 1.2vw;
  padding: 2vw;
}

.titleFont3{
font-family: 'Poppins', sans-serif;
font-size: 1.4vw;
line-height: 1.9vw;
text-align: center;
width:80%;
display: flex;
margin:auto;
padding-bottom: 2vw;
}

.titleFont3Sponsor{
font-family: 'Poppins', sans-serif;
font-size: 1.2vw;
line-height: 1.9vw;
text-align: center;
width:80%;
display: flex;
margin:auto;
padding-bottom: 2vw;
}

.titleFont4{
font-family: 'Poppins', sans-serif;
font-size: 1.4vw;
line-height: 1.7vw;
text-align: left;
margin-left: .3vw;
margin-right: .4vw;
padding-bottom: .4vw;
}

.titleFont5{
font-family: 'Poppins', sans-serif;
font-size: 2.5vw;
line-height: 2.5vw;
text-align: center;
margin-top:1vw;
margin-left: 1vw;
margin-right: 1vw;

}

.myList{
margin:1vw;
padding:1vw;
}

.staffMembersBoard2{
font-family: 'Poppins', sans-serif;
font-size: 3vw;
line-height: 1.8vw;
color: #001d3a;
line-height: 1.8vw;
padding-top: 5vw;
padding-bottom: 2vw;
}

.staffMembersBoard{
  margin-top: 0.1vh;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 3vw;
  /*margin-bottom: 3.1vw;*/
  color: #001d3a;
  font-weight: bold;
}

@media screen and (max-width: 1050px) {
  .staffMembersBoard{
    font-size: 4.2vw;
    margin-top: 0.5vh;
  }

  .titleFont1{
    margin-top: 6vh;
  }

  .titleFont2{
    font-size: 1.8vw;
    line-height: 2.2vw;
  }
}

@media screen and (max-width: 750px){
  .staffMembersBoard{
    font-size: 5.8vw;
    margin-top: 2vh;
  }

  .titleFont2{
    font-size: 2.7vw;
    line-height: 3vw;
  }
}


.BoardPageSun {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 5%;
  padding: 1vw;
  margin-top: 2vw;
}

.BoardIntroBar {
width: 50%;
border: 0.1vw solid #001d3a;
margin-top: 3%;
margin-bottom: 3%;
}


.flip-card2 {
overflow:hidden;
margin: 1vw auto;
background-color: transparent;
width: 25vw;
height: 47vw;
}
.flip-card2-inner{
position: relative;
width: 100%;
height: 100%;
text-align: center;
transition: transform 0.8s;
transform-style: preserve-3d;
z-index: -1;
}
.flip-card2:hover .flip-card2-inner{
transform: rotateY(180deg);
}
.flip-card2-front, .flip-card2-back {
position: absolute;
width: 100%;
height: 100%; /* Safari */
backface-visibility: hidden;
}
.flip-card2-front {
width: 25vw;
}
.flip-card2-back {
background-color: #19324d;
color: white;
transform: rotateY(180deg);
}

.flip-card3 {
overflow:hidden;
margin: 1vw auto;
background-color: transparent;
width: 25vw;
height: 39vw;
}
.flip-card3-inner{
position: relative;
width: 100%;
height: 100%;
text-align: center;
transition: transform 0.8s;
transform-style: preserve-3d;
z-index: -1;
}
.flip-card3:hover .flip-card3-inner{
transform: rotateY(180deg);
}
.flip-card3-front, .flip-card3-back {
position: absolute;
width: 100%;
height: 100%; /* Safari */
backface-visibility: hidden;
}
.flip-card3-front {
width: 25vw;
}
.flip-card3-back {
background-color: #19324d;
color: white;
transform: rotateY(180deg);
}

.flip-card4 {
overflow:hidden;
margin: 1vw auto;
background-color: transparent;
width: 25vw;
height: 30vw;
text-align: center;
}
.flip-card4-inner{
position: relative;
width: 100%;
height: 100%;
text-align: center;
transition: transform 0.8s;
transform-style: preserve-3d;
z-index: -1;
}
.flip-card4:hover .flip-card4-inner{
transform: rotateY(180deg);
}
.flip-card4-front, .flip-card4-back {
position: absolute;
width: 100%;
height: 100%; /* Safari */
backface-visibility: hidden;
}
.flip-card4-front {
width: 25vw;
}
.flip-card4-back {
background-color: #19324d;
color: white;
transform: rotateY(180deg);
}

.contactTitle{
    text-align: center;
    font-size: 2vw;
    color: #001d3a;
}

.contactSocials{
    padding: '1vw';
    font-size: '3vw';
    color: 'black';
}

.contactForm{
    text-align: center;
    background-color: #333333;
    width: 40%;
    margin:auto;
    padding: 2vw;
    border-radius: 1vw;
}

.contactComponenet{
    padding: .5vw;
    font-family: 'Poppins', sans-serif;
}

.buttoncontact {
    margin: 0;
    position: absolute;
    left: 50%;
    margin-top: 1%;
    transform: translate(-50%, -50%);
    font-family: 'Poppins', sans-serif;
    display: block;
    font-size: 1vw;
    width: 10%;
    height: 2.5vw;
    color: white;
    background-color: #001d3a;
    border-radius: .1vw;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: transform, background-color, box-shadow;
    text-align: center;
}

.addPoppins {
    font-family: 'Poppins', sans-serif;
}

.contactComponenet::placeholder {
    font-family: 'Poppins', sans-serif;
}

.contactComponenet::-webkit-input-placeholder {
    font-family: 'Poppins', sans-serif;
  }

.contactComponenet:-ms-input-placeholder {
    font-family: 'Poppins', sans-serif;
}

.contactComponenet:-moz-placeholder {
    font-family: 'Poppins', sans-serif;
}

.contactComponenet::-moz-placeholder {
    font-family: 'Poppins', sans-serif;
}

.contactInfo{
    width: 20vw;
    height: 1.8vw;
    border-radius: 1vw;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1vw;
    border: .1vw solid yellow;
}
.navgap {
    margin-top: 125px;
}

.sponsorCards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 2%;
    margin-right: 2%;
}

.card{
    margin-left: 2%;
    margin-right: 2%;
}

.card img{
    width: 20vw;
}

.navgapTutoring {
    margin-top: 115px;
}


.titleFont1Tutoring {
    margin: 1.2vw;
}

.tutoringSunContainer {
    width: 80%;
    margin: auto;
}

.TutorPageSun {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    padding: 1vw;
    margin-top: 2vw;
}

.TutorPageTitle {
    margin-top: 0.1vw;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 4vw;
    margin-bottom: 1.3vw;
    color: #001d3a;
    font-weight: bold;
}

.TutorPageSubtitle1 {
    font-size: 2.3vw;
    font-family: 'Poppins';
    color: gray;
    margin-bottom: 1vw;
    text-align: center;
    margin-top: 3.3vw;
}

.TutorPageDescription1 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    line-height: 1.9vw;
    text-align: center;
    width: 67.6%;
    margin:auto;
}

.TutorPageDescription2 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    line-height: 1.9vw;
    text-align: center;
    width: 67.6%;
    display: flex;
    margin:auto;
}

.TutorPageButton { 
    margin: 0;
    margin-top: 3vw;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Poppins', sans-serif;
    display: block;
    font-size: 1.4vw;
    width: 20%;
    height: 1.5vw;
    padding-top: 0.35vw;
    padding-bottom: 1vw;
    color: white;
    background-color: #001d3a;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: transform, background-color, box-shadow;

    text-decoration: none;
    text-align: center;
}

.TutorPageButton:disabled {
    background-color: rgb(230, 230, 230);
    color: rgb(155, 155, 155);
    pointer-events: none;
}
.TutorPageButton:disabled:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(155, 155, 155);
    pointer-events: none;
}

.TutorPageButton:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}

.TutorSignUpFormContainer {
    margin-left: 20%;
    margin-right: 20%;
    height: 60vw;
}

.TutorSignUpForm {
    align-self: center;
    width: 100%;
    height: 100%;
}

.studentNextSteps {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 1.75vw;
}

.step1Photo {
    width: 75%;
    margin-left: 10%;
    margin-right: 10%;
    height: 100%;
    margin-top: 1vw;
    align-self: center;
}


/* Waiver Styling */
.waiverContainer {
    background-color: rgb(245, 244, 244);
    margin-right: 12%;
    margin-left: 12%;
    border-radius: 2vw;
    height: 100%;
    padding-bottom: 3vw;
}
.waiverTitle {
    margin-top: 0.5vw;
    padding-top: 2vw;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2vw;
    margin-bottom: 1vw;
    color: #001d3a;
    font-weight: bold;
}
.waiverSubtitle {
    margin-top: 0.5vw;
    padding-top: 0.2vw;
    text-align: center;
    font-size: 1.2vw;
    margin-bottom: 2.9vw;
    color: gray;
    font-weight: bold;
}
.waiverDescription1 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    line-height: 1.9vw;
    text-align: left;
    width: 90%;
    margin:auto;
}
.waiverSignatures {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    line-height: 1.9vw;
    text-align: left;
    width: 90%;
    margin:auto;
}
.FormGroupWaiver {
    margin: auto;
    width: 87%;
    padding: 0;
    border-style: none;
    background-color: #ffffff;
    color: cornsilk;
    box-shadow: 0 1px 2px rgb(215, 215, 218), 0 2px 4px rgb(223, 223, 223),
        inset 0 1px 0 #f2f2f2;
    border-radius: 4px;
}
.FormRowWaiver {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}
.FormRowLabelWaiver {
    min-width: 26%;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 1%;
    font-size: 1.2vw;
    color: rgb(150, 150, 150);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.FormRowInput2Waiver {
    font-size: 1.2vw;
    width: 80%;
    padding-top: 0.0%;
    color: #001d3a;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    animation: 1ms void-animation-out;
}
.TutorStudentPageButton {
    margin: 0;
    margin-top: 4vw;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Poppins', sans-serif;
    display: block;
    font-size: 1.4vw;
    width: 20%;
    height: 3.2vw;
    padding-top: 0.35vw;
    padding-bottom: 1vw;
    color: white;
    background-color: #001d3a;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: transform, background-color, box-shadow;

    text-decoration: none;
    text-align: center;
}
.TutorStudentPageButton:disabled {
    background-color: rgb(230, 230, 230);
    color: rgb(155, 155, 155);
    pointer-events: none;
}
.TutorStudentPageButton:disabled:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(155, 155, 155);
    pointer-events: none;
}
.TutorStudentPageButton:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}


/* Phones */
@media screen and (max-width: 600px) {
    .TutorPageSun {
        width: 0%;
        margin-top: 2.6vw;
    }
    .navgapTutoring {
        margin-top: 135px;
    }
    .TutorPageTitle {
        font-size: 14.5vw;
        text-align: left;
        margin-left: 2%;
    }
    .TutorPageSubtitle1 {
        margin-top: 8vw;
        font-size: 7vw;
        text-align: left;
        margin-left: 9%;
        margin-bottom: 3vw;
        width: 90%;
    }
    .TutorPageDescription1 {
        font-size: 3.3vw;
        line-height: normal;
        text-align: left;
        color: gray;
        margin-left: 10%;
    }
    .TutorPageDescription2 {
        font-size: 3vw;
        line-height: normal;
        text-align: left;
        color: rgb(169, 168, 168);
        margin-left: 18%;
        width: 60%;
    }
    .TutorPageButton {
        margin-top: 10.5vw;
        font-size: 4.2vw;
        width: 50%;
        height: 8vw;
        padding-top: 1.2vw;
        padding-bottom: 0vw;
    }
    .TutorSignUpFormContainer{
        width: 100%;
        height: 160vw;
        margin-left: 1%;
    }
    .TutorSignUpForm {
    }
    .studentNextSteps {
        margin-left: 1%;
        margin-right: 1%;
        font-size: 4vw;
    }
    .waiverContainer {
        margin-left: 4%;
        margin-right: 4%;
        height: 100%;
    }
    .waiverTitle {
        font-size: 5vw;
    }
    .waiverSubtitle {
        font-size: 4vw;
        margin-bottom: 3vw;
    }
    .waiverDescription1 {
        font-family: 'Poppins', sans-serif;
        font-size: 3.5vw;
        line-height: normal;
        text-align: left;
        width: 90%;
        margin:auto;
    }
    .waiverSignatures {
        width: 90%;
        font-size: 4.5vw;
    }
    .FormGroupWaiver {
        width: 100%;
    }
    .FormRowLabelWaiver {
        font-size: 4vw;
        margin-top: 4vw;
        margin-bottom: 4vw;
        min-width: 43%;
    }
    .FormRowInput2Waiver {
        width: 50%;
        font-size: 3.5vw;
    }
    .TutorStudentPageButton {
        margin-top: 10.5vw;
        font-size: 4.2vw;
        width: 50%;
        height: 9vw;
        padding-top: 0vw;
        padding-bottom: 0vw;
    }
  }
.headings{
    text-align: center;
    padding-bottom: 30px;
}

.mission {
    float: left;
    width: 45%;
}

.vision {
    float: right;
    margin-left: 2%;
    width: 46.5%;
}

.icon-bar-cart {
  position: fixed;
  top: 85vh;
  right: 5vw;
}

.circle-cart {
  display: flex;
  width: 100px;
  height: 100px;
  background-color: gray;
  border-radius: 50%;
  position: fixed;
  top: 85vh;
  right: 5vw;
}

.tableShop {
  margin-left: auto;
  margin-right: auto;
}

.itemShop {
  padding-left: 6vw;
  padding-right: 6vw;
  padding-bottom: 4.5vw;
  padding-top: 2.3vw;
  font-size: 1.3em;
}

.itemShoprow {
  padding-bottom: 120vw;
}

.comingSoon {
  font-size: 0.95em;
  color: #fcba03;
}

.outOfStock {
  font-size: 0.95em;
  color: red;
}

.inStock {
  font-size: 0.95em;
  color: green;
}
.accountNav {
    background-color: #001d3a;
    height: 45px;
    left: 0;
    margin: 0 auto;
    width: 103.5vw;
    text-align: center;
}

.ulist {
    margin: 0;
    margin-top: 9px;
    padding: 0;
    display: inline-block;
    list-style: none;
}

.mininavli {
    display: inline;
    transition: 0.3s;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 12.2px;
    padding-bottom: 10px;
}

.mininavli:hover {
    transition: ease 0.4s;
    background: rgb(136, 136, 136);
    padding-top: 12.2px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
}


@media (max-width: 750px) {
    .ulist {
        margin: 0;
        margin-top: 10px;
        padding: 0;
        display: inline-block;
        list-style: none;
    }
    .mininavli {
        font-size: 16px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 11px;
    }
    .mininavli:hover {
        transition: ease 0.4s;
        background: rgb(136, 136, 136);
        padding-top: 14px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 11px;
    }
}
.FormGroupPC {
    margin: auto;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    width: 50%;
    margin-top: 4vw;
    margin-bottom: 1.5vw;
    padding: 0;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    border-style: none;
    background-color: #ffffff;
    color: cornsilk;
    box-shadow: 0 1px 2px rgb(215, 215, 218), 0 2px 4px rgb(223, 223, 223),
        inset 0 1px 0 #f2f2f2;
    border-radius: 4px;
}

.FormRowPC {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 1%;
    padding-bottom: 1%;
}

.FormRowInput::placeholder {
    color: rgb(155, 155, 155);
  }

.buttonRESET {
    font-family: 'Poppins', sans-serif;
    font-size: 1vw;
    min-width: 20%;
    margin-bottom: 1%;
    text-align: center;
    margin-left: 40%;
    height: 2.2vw;
    color: white;
    background-color: #001d3a;
    /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); */
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: background-color, box-shadow;
}

.buttonRESET:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}

.buttonRESET:disabled {
    background-color: rgb(230, 230, 230);
    color: rgb(155, 155, 155);
    pointer-events: none;
}

.buttonRESET:disabled:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(155, 155, 155);
    pointer-events: none;
}


@media (max-width: 900px) {
    .FormGroupPC {
        width: calc(75% + 1vw);
    }
    .FormRowInput {
        font-size: 22px;
    }
    .buttonRESET {
        font-size: 2vw;
        height: 3vw;
        min-width: 25%;
        padding-bottom: 4.5%;
    }
}


.navgapA {
    margin-top: 115px;
}

.haccount {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 3.1vw;
    color: #001d3a;
    margin-top: 30px;
}
.haccountMerch {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 1vw;
    color: #001d3a;
    margin-top: 0px;
}

.haccountMerchItem {
    font-family: 'Poppins', sans-serif;
    font-size: 2.9em;
    color: #001d3a;
}

.graa {
    background-color: rgb(249, 247, 247);
}

.adminPageLink {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  font-size: 16px;
  height: 26px;
  color: white;
  margin-right: 40%;
  border-radius: 4px;
  margin-left: 40%;
  background-color: #001d3a;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  margin-top: 40px;
}

.signInButton a{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;   
    background-color: #001d3a;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 4px;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    height: 50px;
    margin-left: 40%;
    margin-right: 40%;
}

.signInButton:hover(){
    cursor: pointer;
}
@keyframes rotl {
    0% {
        transform: rotate(180deg);
    }
}

@keyframes rotr {
    0% {
        transform: rotate(-180deg);
    }
}

#rightsuntb {
    height: 6%;
    width: 6%;
    margin-right: 12%;
}

#rightsuntb:hover {
    animation: 1.2s 0s 1 rotl;
}

#leftsuntb {
    height: 6%;
    width: 6%;
    margin-left: 12%;
}

#leftsunM {
    height: 7%;
    width: 7%;
    margin-right: 4.5%;
    margin-top: 1%;
}

#rightsunM {
    height: 7%;
    width: 7%;
    margin-left: 4.5%;
    margin-top: 1%;
}

#leftsuntb:hover {
    animation: 1.2s 0s 1 rotr;
}

#leftsunM:hover {
    animation: 1.2s 0s 1 rotr;
}
#rightsunM:hover {
    animation: 1.2s 0s 1 rotr;
}

.hubbox {
    display: flex;
    justify-content: center;
}

.hubintro {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    margin-top: 0.4%;
    margin-bottom: -3.4%;
    margin-right: 3.5%;
    margin-left: 3.5%;
}

.ulTB {
    text-align: left;
    background-color: rgba(218, 217, 217, 0.954);
    border-radius: 2.5%;
    margin-left: 18.5%;
    margin-right: 18%;
    margin-top: 3.2%;
    padding: 5%;
    padding-top: 1.5%;
    padding-bottom: 1.3%;
    color: rgb(101, 101, 101);
    transition: ease-in-out 0.33s;
    background-color: rgba(179, 178, 178, 0.55);
    color: rgb(77, 77, 77);
}

.ulTB:hover {
    background-color: rgba(218, 217, 217, 0.954);
    color: rgb(101, 101, 101);
    transition: ease-in-out 0.33s;
}

.liTB {
    padding-top: 0.8%;
    padding-bottom: 0.8%;
}

.bankHUBsvgdiv {
    margin-top: 8%;
    display: flex;
    justify-content: center;
}

.choiceHUBsvgdiv {
    margin-top: 2.5%;
    display: flex;
    justify-content: center;
}

.bankHUBsvg {
    width: 14%;
    height: 14%;
    margin-left: -15.5%;
    margin-right: -15.5%;
    align-self: center;
}

.itemHUB {
    width: 25%;
    height: 25%;
    margin-left: 8%;
    margin-right: 8%;
    padding-left: 0%;
    border-radius: 1vw;
    padding-top: 1%;
    padding-bottom: 1%;
    align-self: center;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.35vw;
    color: #001d3a;
    text-decoration: none;
    transition: ease-in-out 0.2s;
    background-color: rgba(179, 178, 178, 0.55);
}

.itemHUB:hover {
    background-color: #98b7b6; /* #f7c762; */
    transition: ease-in-out 0.2s;
}

.itemHUBsvg {
    width: 42%;
    height: 42%;
    align-self: center;
}


@media screen and (max-width: 700px) {
    #rightsunM {
        height: 15%;
        width: 15%;
        margin-left: 7%;
        margin-top: 10%;
    }
    #leftsunM {
        height: 15%;
        width: 15%;
        margin-right: 7%;
        margin-top: 10%;
    }
    
}
.hubintroTS {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    margin-top: -0.3%;
    margin-bottom: -3.4%;
    margin-right: 3.5%;
    margin-left: 3.5%;
}

#rightsunTS {
    height: 6%;
    width: 6%;
    margin-right: 12%;
    margin-top: -0.6%;
}

#rightsunTS:hover {
    animation: 1.2s 0s 1 rotl;
}

#leftsunTS {
    height: 6%;
    width: 6%;
    margin-left: 12%;
    margin-top: -0.6%;
}

#leftsunTS:hover {
    animation: 1.2s 0s 1 rotr;
}

.carousel {
    z-index: 0;
}

.FormGroupTS {
    margin: auto;
    font-family: 'Poppins', sans-serif;
    width: 50%;
    margin-top: 4vw;
    margin-bottom: 1.5vw;
    padding: 0;
    padding-top: 0.5vw;
    padding-bottom: 0.5vw;
    border-style: none;
    background-color: #ffffff;
    color: cornsilk;
    box-shadow: 0 1px 2px rgb(215, 215, 218), 0 2px 4px rgb(223, 223, 223),
        inset 0 1px 0 #f2f2f2;
    border-radius: 4px;
}

.FormRowTS {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.FormRowTV {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 0px;
}

.FormRowTS:first-child {
    border-top: none;
}

.FormRowLabelDropDownTS {
    width: 40%;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 3%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    color: rgb(150, 150, 150);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.FormRowLabelDropDownTestUpload {
    width: 100%;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 3%;
    margin-right: 4%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    color: rgb(150, 150, 150);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.FormRowLabelDropDownTestView {
    width: 100%;
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 3%;
    margin-right: -2%;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    color: rgb(150, 150, 150);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.classitem {
    margin-left: 5%;
    margin-top: 4%;
    padding: 1.25vw;
    padding-top: 2vmin;
    padding-bottom: 2vmin;
    font-size: 18;
    box-sizing: border-box;
    width: 100%;
    color: black;
    border: 0.1vmin solid rgb(213, 201, 218);
    border-start-end-radius: 1vw;
    border-radius: 1vw;
    overflow: auto;
    float: right;
    position: relative;
}

.FormRowInputTS {
    text-align: left;
    font-size: 1.2vw;
    width: 0%;
    color: #001d3a;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    animation: 1ms void-animation-out;
}

.FormRowInputViewTests {
    font-size: 1.2vw;
    width: 80%;
    padding-top: 0.0%;
    color: #001d3a;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    animation: 1ms void-animation-out;
    margin-left: -5vw;
}

.hrTS {
    color: white;
    border: 0.01vw solid rgba(218, 218, 218, 0.338);
    width: 85%;
}

.disclaimer3 {
    font-size: 0.9vw;
    font-family: 'Poppins', sans-serif;
    color: rgb(169, 169, 169);
    margin-left: 11.5%;
    margin-right: 5.5%;
    margin-top: 0%;
    margin-bottom: 2%;
    font-style: italic;
}

.disclaimer4 {
    font-size: 0.9vw;
    font-family: 'Poppins', sans-serif;
    color: #001d3a;
    margin-left: 40%;
    margin-right: 5.5%;
    margin-top: 0%;
    margin-bottom: 2%;
    margin: auto;
    width: 30%;
    padding-left: 40%;
    text-align: center;
    font-style: italic;
}

.checkboxTS {
    margin-left: 5.2%;
    margin-right: 3%;
    background: #001d3a;
}

.checkboxTSinvis {
    margin-left: 5.2%;
    margin-right: 3%;
    background: #001d3a;
    height: 1%;
}

.progressbarTS {
    width: 60%;
    margin-top: 1vw;
    margin: auto;
    padding: 0;
    text-align: center;
    border: 0.05vw solid rgba(176, 176, 176, 0.701);
    border-radius: 0.1vw;
}

.uploadSuccess {
    text-align: center;
    font-size: 0.9vw;
    font-family: 'Poppins';
    color: #001d3a;
}

.nextprevdiv {
    margin-top: 1vw;
    margin: auto;
    padding: 0;
    width: 50%;
    text-align: center;
}

.nextprevdivlifted {
    margin: auto;
    margin-top: -24vw;
    padding: 0;
    width: 50%;
    text-align: center;
}

.nextprevdivlifted2 {
    margin: auto;
    margin-top: -12vw;
    padding: 0;
    width: 50%;
    text-align: center;
}

.buttonSlider {
    font-family: 'Poppins', sans-serif;
    font-size: 1.3vw;
    width: 20%;
    margin-left: 4%;
    margin-right: 4%;
    height: 2.8vw;
    color: white;
    background-color: #001d3a;
    /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); */
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.buttonADD {
    font-family: 'Poppins', sans-serif;
    font-size: 1vw;
    width: 12%;
    margin-bottom: 1%;
    text-align: center;
    margin-left: 44%;
    height: 2.2vw;
    color: white;
    background-color: #001d3a;
    /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); */
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: transform, background-color, box-shadow;
}

.buttonADD:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}

.buttonADD:disabled {
    background-color: rgb(230, 230, 230);
    color: rgb(155, 155, 155);
    pointer-events: none;
}

.buttonADD:disabled:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(155, 155, 155);
    pointer-events: none;
}

.buttonSlider:disabled {
    background-color: rgb(230, 230, 230);
    color: rgb(155, 155, 155);
    pointer-events: none;
}
.buttonSlider:disabled:hover {
    background-color: rgb(221, 221, 221);
    color: rgb(155, 155, 155);
    pointer-events: none;
}

.buttonSlider:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}
.alumnilist {
    margin: auto;
    padding-bottom: 1vw;
    padding-top: 0.01vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    width: calc(80% - 1vw);
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: #001d3acf;
    box-shadow: 0 1px 2px rgb(215, 215, 218), 0 2px 4px rgb(223, 223, 223),
        inset 0 1px 0 #f2f2f2;
    border-radius: 3px;
    background-color: white;
}
.alumniList {
    float: left;
    min-width: 96%;
    margin-right: 2%;
    margin-left: 2%;
}

.noAlum {
    font-size: 1.2vw;
    line-height: 1.2vw;
    color: rgb(150, 150, 150);
   
    text-align: left;
    margin-left: 5%;
    font-style: italic;
    
}

.alumnetbio {
    font-size: 1vw;
    line-height: 1.2vw;
    color: rgb(114, 114, 114);
    font-style: italic;
    text-align: left;
    margin-left: 3%;
    
}

.alumName{
    color: #001d3acf;
    margin: auto;
    font-size: 1.6vw;
    line-height: 2.1vw;
    float: left;
}

.alumGradYear {
    color: #001d3acf;
    margin: auto;
    font-size: 1.7vw;
    line-height: 2.1vw;
    float: right;
}

.alumMajor {
    color: black;
    margin: auto;
    font-size: 1.3vw;
    float: left;
    line-height: 1.7vw;
}

.alumEmployer{
    color: black;
    margin: auto;
    font-size: 1.3vw;
    float: right;
    line-height: 1.7vw;
}

.alumContact{
    text-align: center;
    font-size: 1.2vw;
}

.alumBreak{
    line-height: 3.1vw;
    font-size: 2.9vw;
    align-self: start;
}

@media screen and (max-width: 500px){
    .alumBreak{
        line-height: 0vw;
        height: 0vw;
    }
}

.itemOrderForm {
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    padding: 1rem;
    border-style: none;
    background-color: #ffffff;
    color: #001d3a;
    box-shadow: 0 1px 2px rgb(196, 196, 199), 0 1px 3px rgb(184, 181, 181),
        inset 0 1px 2px #e4e1e1;
    border-radius: 4px;
    width: 75%
}

.itemOrderFormRow {
    display: flex;
    align-items: center;
    margin-left: 1em;
    margin-right: 1em;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
}

.itemDropDown {
    display: flex;
    font-size: 1em;
    align-items: center;
    margin-left: 1em;
    margin-right: 1em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    border: none;
    font-family: 'Poppins', sans-serif;
}

.itemOrderFormRowInput {
    font-size: 1.1em;
    width: 80%;
    padding-top: 0.0%;
    color: #001d3a;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    animation: 1ms void-animation-out;
    outline: none;
    border-style: none;
}

.itemOrderFormRowInput::placeholder {
    color: #a7a7acc6;
}

.itemOrderFormNumber {
    outline: none;
    border-style: none;
    color: #001d3a;
    font-size: 1.1em;
    font-family: 'Poppins', sans-serif;
}

.itemOrderSubmitButton {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    min-width: 20%;
    text-align: center;
    margin-left: 40%;
    height: 2em;
    color: white;
    background-color: #001d3a;
    /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); */
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: background-color, box-shadow;
    outline: none;
    border-style: none;
}

.itemOrderSubmitButtonRe {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    min-width: 20%;
    text-align: center;
    height: 2em;
    color: white;
    background-color: #001d3a;
    /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); */
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: background-color, box-shadow;
    outline: none;
    border-style: none;
}

.loader {
    border: 0.8rem solid #eae8e8;
    border-radius: 50%;
    border-top: 0.8rem solid #001d3a;
    width: 2rem;
    height: 2rem;
    margin-left: 40%; /* Safari */
    animation: spin 2s linear infinite;
  }

/* Safari */
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.haccountRe {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    margin-bottom: 3.1vw;
    color: #001d3a;
    margin-top: 30px;
}

.hButton {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin-bottom: 3.1vw;
    background-color: #001d3a;
    margin-top: 30px;
    color: white;
    border-radius: 4px;
    border-style: none;
}

.itemOrderSubmitButton:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}

.itemOrderSubmitButton:disabled {
    background-color: rgb(114, 114, 114);
    color: #d2cece;
    pointer-events: none;
}
.itemOrderSubmitButton:disabled:hover {
    background-color: rgb(114, 114, 114);
    color: #d2cece;
    pointer-events: none;
}

.orderFormTitle {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5em;
    margin-bottom: 1vh;
    color: #001d3a;
    text-align: center;
}


.itemPageOutmostDiv {
    display: flex;
}

.itemPageFlex1 {
    flex: 1 1;
    margin-left: 10vh;
}

.itemPageFlex2 {
    flex: 1 1;
    margin-top: 19vh;
}

.itemCarousel {
    margin-top: 20vh;
    margin-left: 10vh;
}



/* Mobile support */
@media screen and (max-width: 600px) {
    .itemPageOutmostDiv {
        display: table-row;
    }

    .itemPageFlex1 {
        width: 90vw;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        margin: auto;
    }
    .itemPageFlex2 {
        width: 90vw;
        align-items: center;
        margin-top: 4vh;
    }
    .itemCarousel {
        margin-top: 20vh;
        margin-left: 0vh;
        width: 100vw;
    }

    .haccountMerchItem {
        font-size: 2.1rem;
        margin-top: 8vw;
    }

}
.itemOrderForm {
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    padding: 1rem;
    border-style: none;
    background-color: #ffffff;
    color: #001d3a;
    box-shadow: 0 1px 2px rgb(196, 196, 199), 0 1px 3px rgb(184, 181, 181),
        inset 0 1px 2px #e4e1e1;
    border-radius: 4px;
    width: 75%
}

.itemOrderFormRow {
    display: flex;
    align-items: center;
    margin-left: 1em;
    margin-right: 1em;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
}

.itemOrderFormRowInput {
    font-size: 1.1em;
    width: 80%;
    padding-top: 0.0%;
    color: #001d3a;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    animation: 1ms void-animation-out;
    outline: none;
    border-style: none;
}

.itemOrderFormRowInput::placeholder {
    color: #a7a7acc6;
}

.itemOrderFormNumber {
    outline: none;
    border-style: none;
    color: #001d3a;
    font-size: 1.1em;
    font-family: 'Poppins', sans-serif;
}

.itemOrderSubmitButton {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    min-width: 20%;
    text-align: center;
    margin-left: 40%;
    height: 2em;
    color: white;
    background-color: #001d3a;
    /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08); */
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    will-change: background-color, box-shadow;
    outline: none;
    border-style: none;
}

.itemOrderSubmitButton:hover {
    background-color: #98b7b6;
    color: #f5f4f4;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}

.orderFormTitle {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5em;
    margin-bottom: 1vh;
    color: #001d3a;
    text-align: center;
}


.itemPageOutmostDiv {
    display: flex;
}

.itemPageFlex1 {
    flex: 1 1;
    margin-left: 10vh;
}

.itemPageFlex2 {
    flex: 1 1;
    margin-top: 19vh;
}

.itemCarousel {
    margin-top: 20vh;
    margin-left: 10vh;
}



/* Mobile support */
@media screen and (max-width: 600px) {
    .itemPageOutmostDiv {
        display: table-row;
    }

    .itemPageFlex1 {
        width: 90vw;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        margin: auto;
    }
    .itemPageFlex2 {
        width: 90vw;
        align-items: center;
        margin-top: 4vh;
    }
    .itemCarousel {
        margin-top: 20vh;
        margin-left: 0vh;
        width: 100vw;
    }

    .haccountMerchItem {
        font-size: 2.1rem;
        margin-top: 8vw;
    }

}
 .mission {
    float: right;
    width: 45%;
    margin-left: 10%;
    text-align: left;
    font-size: 1.1vw;
}

.about {
    float: left;
    width: 45%;
    margin-left: 5%;
    text-align: left;
    font-size: 1.1vw;
}

.sponsor{
    font-size: 1.5vw;
    margin-top: 5%;
    font-weight: bold;
}

.header{
    text-align: center;
    font-size: 2.3vw;
    color: gray;
    margin-top: -1%;
    margin-bottom: 1%;
    font-weight: 300;
}
.img {
    display: block;
    margin-left: auto;
    margin-right: auto; 
    width: 50%;
	height: 10%
}
.caption {
    margin-top: 1%;
    color: '#001d3a';
    font-size: 1.5vw;
}
.slack {
    margin-top: 3%;
    margin-bottom: 5%;
    font-size: 3vw;
    color:'#001d3a';
    text-align: center;

}

.a:link {
    color: blue;
    text-align: center;
    background-color: transparent;
    text-decoration: none;
  }
.a:visited{
    color: blue;
    text-decoration: none;
}
.a:hover{
    color: purple;
    background-color: transparent;
    text-decoration: none;
}

@media screen and (max-width: 600px){
    .mission {
        width: 45%;
        margin-left: 10%;
        font-size: 1.9vw;
    }
    
    .about {
        width: 45%;
        font-size: 1.9vw;
        margin-left: 1%;
    }

    .sponsor{
        font-size: 2.5vw;
    }
    
    .header{
        font-size: 4vw;
    }
    .img {
        display: block;
        margin-left: auto;
        margin-right: auto; 
        width: 60%;
        height: 20%
    }
    .caption {
        margin-top: 1%;
        color: '#001d3a';
        font-size: 2.5vw;
    }
    .slack {
        margin-top: 3%;
        margin-bottom: 5%;
        font-size: 4vw;
        color:'#001d3a';
        text-align: center;
    
    }
    .header{
        font-size: 4vw;
    }
}


.signInForm{
    position: relative;
    width: 70%;
    overflow: hidden;
    padding-top: 100%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 3.8vh;
    margin-top: 3.8vh;
    border-style: solid;
    border-color: #001d3a;
}

.responsive-iframe{
    position: absolute;
    width: 100%;
    height: 100%;
    
    border: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

}

.gmIntro
{
    font-family: 'Poppins';
    font-size: 2vw;
    margin-top: 6vh;
    font-weight: 415;
    color: #001d3a;
    line-height: 1.5vw;
}

.gmSun {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    padding: 1vw;
    margin-top: 2vw;
  }

.gmSlideButton{
    background-color: rgba(254, 254, 84, 0.892);
    color: black;
    text-decoration: none;
    text-align: center;
    border-radius: 15px;
    border: none;
    display: inline-block;
    padding: 15px 15px;
    font-size: 20px;
    margin: 20px;
    cursor: pointer;
    font-family: Poppins;
}

.gmSlideButton:hover(){
    cursor: pointer;
}

.gmSlides{
    margin-left: 15%;
    margin-right: 15%;
    align-self: center;
    text-align: center;
}



@media screen and (max-width: 800px) {
    .signInForm{
        width: 90%;
        margin-left: 5%;
        margin-left: 5%;
        border-width: medium;
    }

    .gmSlides{
        margin-left: 5%;
        margin-left: 5%;
    }

    .gmIntro {
        font-size: 4vw;
    }
    .gmSun {
        width: 20%;
        padding-bottom: 5%;
    }
}
