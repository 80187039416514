@keyframes rotl {
    0% {
        transform: rotate(180deg);
    }
}

@keyframes rotr {
    0% {
        transform: rotate(-180deg);
    }
}

#rightsuntb {
    height: 6%;
    width: 6%;
    margin-right: 12%;
}

#rightsuntb:hover {
    animation: 1.2s 0s 1 rotl;
}

#leftsuntb {
    height: 6%;
    width: 6%;
    margin-left: 12%;
}

#leftsunM {
    height: 7%;
    width: 7%;
    margin-right: 4.5%;
    margin-top: 1%;
}

#rightsunM {
    height: 7%;
    width: 7%;
    margin-left: 4.5%;
    margin-top: 1%;
}

#leftsuntb:hover {
    animation: 1.2s 0s 1 rotr;
}

#leftsunM:hover {
    animation: 1.2s 0s 1 rotr;
}
#rightsunM:hover {
    animation: 1.2s 0s 1 rotr;
}

.hubbox {
    display: flex;
    justify-content: center;
}

.hubintro {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2vw;
    margin-top: 0.4%;
    margin-bottom: -3.4%;
    margin-right: 3.5%;
    margin-left: 3.5%;
}

.ulTB {
    text-align: left;
    background-color: rgba(218, 217, 217, 0.954);
    border-radius: 2.5%;
    margin-left: 18.5%;
    margin-right: 18%;
    margin-top: 3.2%;
    padding: 5%;
    padding-top: 1.5%;
    padding-bottom: 1.3%;
    color: rgb(101, 101, 101);
    transition: ease-in-out 0.33s;
    background-color: rgba(179, 178, 178, 0.55);
    color: rgb(77, 77, 77);
}

.ulTB:hover {
    background-color: rgba(218, 217, 217, 0.954);
    color: rgb(101, 101, 101);
    transition: ease-in-out 0.33s;
}

.liTB {
    padding-top: 0.8%;
    padding-bottom: 0.8%;
}

.bankHUBsvgdiv {
    margin-top: 8%;
    display: flex;
    justify-content: center;
}

.choiceHUBsvgdiv {
    margin-top: 2.5%;
    display: flex;
    justify-content: center;
}

.bankHUBsvg {
    width: 14%;
    height: 14%;
    margin-left: -15.5%;
    margin-right: -15.5%;
    align-self: center;
}

.itemHUB {
    width: 25%;
    height: 25%;
    margin-left: 8%;
    margin-right: 8%;
    padding-left: 0%;
    border-radius: 1vw;
    padding-top: 1%;
    padding-bottom: 1%;
    align-self: center;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.35vw;
    color: #001d3a;
    text-decoration: none;
    transition: ease-in-out 0.2s;
    background-color: rgba(179, 178, 178, 0.55);
}

.itemHUB:hover {
    background-color: #98b7b6; /* #f7c762; */
    transition: ease-in-out 0.2s;
}

.itemHUBsvg {
    width: 42%;
    height: 42%;
    align-self: center;
}


@media screen and (max-width: 700px) {
    #rightsunM {
        height: 15%;
        width: 15%;
        margin-left: 7%;
        margin-top: 10%;
    }
    #leftsunM {
        height: 15%;
        width: 15%;
        margin-right: 7%;
        margin-top: 10%;
    }
    
}